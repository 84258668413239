<template>
  <div>
    <v-card :key="id">
      <v-card-text v-if="QuotData.Typetransportation">
        <QuotData class="mt-8"
                  :edit-mode="true"
                  :typetransportation="QuotData.Typetransportation"
                  :type-service="QuotData.TypeService"
                  :inconterm="QuotData.Inconterm"
                  v-bind="QuotData"
                  :filterRoute="QuotData.filterRoute"
                  v-on:QuotData="RecibeData">
          <template slot="autocomplete">

            <div class="row no-gutters position-relative" v-if="showComponent==1 && DataOrder.deliveryTypeId == 1">
              <div class="col-6 fixed-col-size">
                <googleMapsAutocompleteVuetifyNew
                    ref="originAutocomplete"
                    label="Origen"
                    :search="originAddress"
                    :items="originItems"
                    :selections="origin"
                    transportation="SEA"
                    :filterRoute="1"
                    :placeholder="DataOrder.origin[0].address"
                    @change="originSelected"
                    @updateAddress="updateOriginAddress"
                    @updateItems="updateOriginItems"
                    :id="1"
                >
                </googleMapsAutocompleteVuetifyNew>
              </div>
              <div class="col-6 fixed-col-size">
                <googleMapsAutocompleteVuetifyNew
                    ref="destinationAutocomplete"
                    label="Destino"
                    :search="destinationAddress"
                    :items="destinationItems"
                    :selections="destination"
                    transportation="SEA"
                    :filterRoute="1"
                    :placeholder="DataOrder.destination[0].address"
                    @change="destinationSelected"
                    @updateAddress="updateDestinationAddress"
                    @updateItems="updateDestinationItems"
                    :id="2"
                >
                </googleMapsAutocompleteVuetifyNew>
              </div>
            </div>

            <div class="row no-gutters position-relative" v-if="showComponent==1 && DataOrder.deliveryTypeId == 2">
              <div class="col-6 fixed-col-size">
                <googleMapsAutocompleteVuetifyNew
                    ref="originAutocomplete"
                    label="Origen"
                    :search="originAddress"
                    :items="originItems"
                    :selections="origin"
                    transportation="SEA"
                    :filterRoute="2"
                    :placeholder="DataOrder.origin[0].address"
                    @change="originSelected"
                    @updateAddress="updateOriginAddress"
                    @updateItems="updateOriginItems"
                    :id="1"
                >
                </googleMapsAutocompleteVuetifyNew>
              </div>
              <div class="col-6 fixed-col-size">
                <googleMapsAutocompleteVuetifyNew
                    ref="destinationAutocomplete"
                    label="Destino"
                    :search="destinationAddress"
                    :items="destinationItems"
                    :selections="destination"
                    transportation="SEA"
                    :filterRoute="2"
                    :placeholder="DataOrder.destination[0].address"
                    @change="destinationSelected"
                    @updateAddress="updateDestinationAddress"
                    @updateItems="updateDestinationItems"
                    :id="2"
                >
                </googleMapsAutocompleteVuetifyNew>
              </div>
            </div>
            <div class="row no-gutters position-relative" v-if="showComponent==1 && DataOrder.deliveryTypeId == 3">
              <div class="col-6 fixed-col-size">
                <googleMapsAutocompleteVuetifyNew
                    ref="originAutocomplete"
                    label="Origen"
                    :search="originAddress"
                    :items="originItems"
                    :selections="origin"
                    transportation="SEA"
                    :filterRoute="2"
                    :placeholder="DataOrder.origin[0].address"
                    @change="originSelected"
                    @updateAddress="updateOriginAddress"
                    @updateItems="updateOriginItems"
                    :id="1"
                >
                </googleMapsAutocompleteVuetifyNew>
              </div>
              <div class="col-6 fixed-col-size">
                <googleMapsAutocompleteVuetifyNew
                    ref="destinationAutocomplete"
                    label="Destino"
                    :search="destinationAddress"
                    :items="destinationItems"
                    :selections="destination"
                    transportation="SEA"
                    :filterRoute="1"
                    :placeholder="DataOrder.destination[0].address"
                    @change="destinationSelected"
                    @updateAddress="updateDestinationAddress"
                    @updateItems="updateDestinationItems"
                    :id="2"
                >
                </googleMapsAutocompleteVuetifyNew>
              </div>
            </div>
            <div class="row no-gutters position-relative" v-if="showComponent==1 && DataOrder.deliveryTypeId == 4">
              <div class="col-6 fixed-col-size">
                <googleMapsAutocompleteVuetifyNew
                    ref="originAutocomplete"
                    label="Origen"
                    :search="originAddress"
                    :items="originItems"
                    :selections="origin"
                    transportation="SEA"
                    :filterRoute="1"
                    :placeholder="DataOrder.origin[0].address"
                    @change="originSelected"
                    @updateAddress="updateOriginAddress"
                    @updateItems="updateOriginItems"
                    :id="1"
                >
                </googleMapsAutocompleteVuetifyNew>
              </div>
              <div class="col-6 fixed-col-size">
                <googleMapsAutocompleteVuetifyNew
                    ref="destinationAutocomplete"
                    label="Destino"
                    :search="destinationAddress"
                    :items="destinationItems"
                    :selections="destination"
                    transportation="SEA"
                    :filterRoute="2"
                    :placeholder="DataOrder.destination[0].address"
                    @change="destinationSelected"
                    @updateAddress="updateDestinationAddress"
                    @updateItems="updateDestinationItems"
                    :id="2"
                >
                </googleMapsAutocompleteVuetifyNew>
              </div>
            </div>
            <div class="row no-gutters position-relative" v-if="showComponent==2 && DataOrder.deliveryTypeId == 1">
              <div class="col-6 fixed-col-size">
                <s-air-port-auto-complete
                    ref="originAutocomplete"
                    label="Origen"
                    :search="originAddress"
                    :items="originItems"
                    :selections="origin"
                    :filterRoute="1"
                    @change="originSelectedAereo"
                    @updateAddress="updateOriginAddress"
                    @updateItems="updateOriginItems"
                    :id="1"
                    transportation="AIR"
                    :placeholder="DataOrder.origin[0].address"
                >
                </s-air-port-auto-complete>
              </div>
              <div class="col-6 fixed-col-size">
                <s-air-port-auto-complete
                    ref="destinationAutocomplete"
                    label="Destino"
                    :search="destinationAddress"
                    :items="destinationItems"
                    :selections="destination"
                    :filterRoute="1"
                    @change="destinationSelectedAereo"
                    @updateAddress="updateDestinationAddress"
                    @updateItems="updateDestinationItems"
                    :id="2"
                    transportation="AIR"
                    :placeholder="DataOrder.destination[0].address"
                >
                </s-air-port-auto-complete>
              </div>
            </div>
            <div class="row no-gutters position-relative" v-if="showComponent==2 && DataOrder.deliveryTypeId == 2">
              <div class="col-6 fixed-col-size">
                <s-air-port-auto-complete
                    ref="originAutocomplete"
                    label="Origen"
                    :search="originAddress"
                    :items="originItems"
                    :selections="origin"
                    :filterRoute="2"
                    @change="originSelectedAereo"
                    @updateAddress="updateOriginAddress"
                    @updateItems="updateOriginItems"
                    :id="1"
                    transportation="AIR"
                    :placeholder="DataOrder.origin[0].address"
                >
                </s-air-port-auto-complete>
              </div>
              <div class="col-6 fixed-col-size">
                <s-air-port-auto-complete
                    ref="destinationAutocomplete"
                    label="Destino"
                    :search="destinationAddress"
                    :items="destinationItems"
                    :selections="destination"
                    :filterRoute="2"
                    @change="destinationSelectedAereo"
                    @updateAddress="updateDestinationAddress"
                    @updateItems="updateDestinationItems"
                    :id="2"
                    transportation="AIR"
                    :placeholder="DataOrder.destination[0].address"
                >
                </s-air-port-auto-complete>
              </div>
            </div>
            <div class="row no-gutters position-relative" v-if="showComponent==2 && DataOrder.deliveryTypeId == 3">
              <div class="col-6 fixed-col-size">
                <s-air-port-auto-complete
                    ref="originAutocomplete"
                    label="Origen"
                    :search="originAddress"
                    :items="originItems"
                    :selections="origin"
                    :filterRoute="2"
                    @change="originSelectedAereo"
                    @updateAddress="updateOriginAddress"
                    @updateItems="updateOriginItems"
                    :id="1"
                    transportation="AIR"
                    :placeholder="DataOrder.origin[0].address"
                >
                </s-air-port-auto-complete>
              </div>
              <div class="col-6 fixed-col-size">
                <s-air-port-auto-complete
                    ref="destinationAutocomplete"
                    label="Destino"
                    :search="destinationAddress"
                    :items="destinationItems"
                    :selections="destination"
                    :filterRoute="1"
                    @change="destinationSelectedAereo"
                    @updateAddress="updateDestinationAddress"
                    @updateItems="updateDestinationItems"
                    :id="2"
                    transportation="AIR"
                    :placeholder="DataOrder.destination[0].address"
                >
                </s-air-port-auto-complete>
              </div>
            </div>
            <div class="row no-gutters position-relative" v-if="showComponent==2 && DataOrder.deliveryTypeId == 4">
              <div class="col-6 fixed-col-size">
                <s-air-port-auto-complete
                    ref="originAutocomplete"
                    label="Origen"
                    :search="originAddress"
                    :items="originItems"
                    :selections="origin"
                    :filterRoute="1"
                    @change="originSelectedAereo"
                    @updateAddress="updateOriginAddress"
                    @updateItems="updateOriginItems"
                    :id="1"
                    transportation="AIR"
                    :placeholder="DataOrder.origin[0].address"
                >
                </s-air-port-auto-complete>
              </div>
              <div class="col-6 fixed-col-size">
                <s-air-port-auto-complete
                    ref="destinationAutocomplete"
                    label="Destino"
                    :search="destinationAddress"
                    :items="destinationItems"
                    :selections="destination"
                    :filterRoute="2"
                    @change="destinationSelectedAereo"
                    @updateAddress="updateDestinationAddress"
                    @updateItems="updateDestinationItems"
                    :id="2"
                    transportation="AIR"
                    :placeholder="DataOrder.destination[0].address"
                >
                </s-air-port-auto-complete>
              </div>
            </div>

            <div class="row no-gutters position-relative" v-if="showComponent==3">
              <div class="col-6 fixed-col-size">
                <s-land-autocomplete
                    label="Origen"
                    :search="originAddress"
                    :items="originItems"
                    ref="originAutocomplete"
                    :selections="origin"
                    @change="originSelectedTerrestre"
                    @updateAddress="updateOriginAddress"
                    @updateItems="updateOriginItems"
                    :placeholder="DataOrder.origin[0].address"
                    transportation="SEA"
                    :id="1"
                ></s-land-autocomplete>
              </div>
              <div class="col-6 fixed-col-size">
                <s-land-autocomplete
                    label="Destino"
                    :search="destinationAddress"
                    :items="destinationItems"
                    :selections="destination"
                    transportation="SEA"
                    ref="destinationAutocomplete"
                    :placeholder="DataOrder.destination[0].address"
                    @change="destinationSelectedTerrestre"
                    @updateAddress="updateDestinationAddress"
                    @updateItems="updateDestinationItems"
                    :id="2"
                >
                </s-land-autocomplete>
              </div>
            </div>
          </template>

        </QuotData>
        <div v-if="QuotData.Typetransportation">

          <Maritimo v-if="showComponent == 1"
                    @OrderUpdated="closeModal()"
                    @UpdateOrder="UpdateOrder"
                    v-bind:DataOrder="DataOrder"
                    v-bind="QuotData"
                    v-bind:Edit="Edit">
            <template v-slot:btn-quote>
              ¡Actualizar Cotizacion!
            </template>
          </Maritimo>

          <Aereo v-if="showComponent == 2"
                 @OrderUpdated="closeModal()"
                 @UpdateOrder="UpdateOrder"
                 v-bind="QuotData"
                 v-bind:DataOrder="DataOrder"
                 :Edit="Edit"
                 v-bind:LoadData="QuotData.cargo"
                 v-bind:Services="QuotData.Services">
            <template v-slot:btn-quote>
              ¡Actualizar Cotizacion!
            </template>
          </Aereo>

          <Terrestre v-if="showComponent == 3"
                     @OrderUpdated="closeModal()"
                     @UpdateOrder="UpdateOrder"
                     v-bind="QuotData"
                     :Edit="Edit"
                     v-bind:DataOrder="DataOrder"
                     v-bind:LoadData="QuotData.cargo"
                     v-bind:Services="QuotData.Services">
            <template v-slot:btn-quote>
              ¡Actualizar Cotizacion!
            </template>
          </Terrestre>
        </div>
      </v-card-text>
      <v-card-text v-else>
        <v-skeleton-loader
            v-bind="attrs"
            type="article"
        ></v-skeleton-loader>
      </v-card-text>
    </v-card>
  </div>

</template>

<script>
import googleMapsAutocompleteVuetifyNew from "@/components/google-maps-autocomplete-vuetify/index_new";
// import googleMapsAutocompleteVuetifyAereo from "@/components/google-maps-autocomplete-vuetify/indexAereo";
import Maritimo from "../Quote/Maritimo";
import QuotData from "../Quote/QuotData";
import Aereo from "../Quote/Aereo";
import Terrestre from "../Quote/Terrestre";
import SAirPortAutoComplete from "@/components/google-maps-autocomplete-vuetify/SAirportAutocomplete";
import SLandAutocomplete from "../google-maps-autocomplete-vuetify/SLandAutocomplete";
import { getS3 } from "../../composables/filesS3";

export default {
  name: "EditQuote",
  components: {
    SLandAutocomplete,
    SAirPortAutoComplete,
    Terrestre,
    Aereo,
    QuotData,
    Maritimo,
    googleMapsAutocompleteVuetifyNew,
    // googleMapsAutocompleteVuetifyAereo
  },
  props: {
    activeEdit: {
      type: Boolean,
      default: false
    },
    id: Number

  },

  watch: {},


  data() {
    return {
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
      showComponent: null,
      Incoterms: [],
      filterRoute:null,
      test: 1042,
      originAddress: "",
      destinationAddress: "",
      originSelections: {},
      destinationSelections: {},
      destinationItems: [],
      originItems: [],
      Transport: [],
      Edit: {},
      QuotData: {
        Typetransportation: null,
        TypeService: null,
        Inconterm: null,
        typeRoute: null,
        dueDate: null,
        filterRoute:null,
        origin: {},
        destination: {},
        searchVariables: {},
        DestinationPort: {},
        OriginPort: {},
        DataService: {},
        Services: {},
        AutocompleteOrigin: false,
        AutocompleteDestination: false,
      },
      Pricers: [],
      origin: {},
      destination: {},
      SalesManagers: [],
      Customers: [],
      DataOrder: [],
      CargoType: [{
        Id: 0,
        Description: 'Container',
        Status: true
      },
        {
          Id: 1,
          Description: 'Pallets',
          Status: true
        }],
      Reasons: [{Id: 0, Reasons: 'No hay disponibilidad', Status: true},
        {Id: 1, Reasons: 'No hay informacion', Status: true},
        {Id: 2, Reasons: 'No hay Ruta', Status: true}],
      headers: [
        {
          text: 'Dessert (100g serving)',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {text: 'Calories', value: 'calories'},
        {text: 'Fat (g)', value: 'fat'},
        {text: 'Carbs (g)', value: 'carbs'},
        {text: 'Protein (g)', value: 'protein'},
        {text: 'Iron (%)', value: 'iron'},
      ],
      desserts: [
        {
          name: 'Frozen Yogurt',
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: '1%',
        },
        {
          name: 'KitKat',
          calories: 518,
          fat: 26.0,
          carbs: 65,
          protein: 7,
          iron: '6%',
        },
      ],
      valid: false,
      dialog: false,
      defaultOrigin: {},
      defaultPol: {},
      defaultDestination: {},
      defaultPod: {},
    }
  },
  methods: {
    GetDataOrder() {
      this.$store.dispatch('working', true);
      this.$http.get(`Orders/${this.id}`).then(({data}) => {
        if (data.code == 200) {
          this.DataOrder = data.data
          this.DataOrder.cargo = data.data.cargo;
          // let cargo = data.data.cargo;
          // console.log('EMpieza', cargo)
         this.setQuoteData();
          // console.log('Termina', cargo)

          this.origin = data.data.origin[0]
          this.defaultOrigin = data.data.origin[0]
          this.defaultPol = data.data.pol;
          this.origin.ports = []
          this.origin.airports = []

          this.destination = data.data.destination[0]
          this.defaultDestination = data.data.destination[0]
          this.defaultPod = data.data.pod;
          this.destination.ports = []
          this.destination.airports = []
          /*let origin = data.data.origin[0]
          let destination = data.data.destination[0]


          */
          this.Edit.edit = true;
          this.Edit.idOrder = this.id;
          this.showComponent = this.DataOrder.transportationId;
          if(this.DataOrder.pol && this.DataOrder.pod){
            if (this.DataOrder.pol.city == this.DataOrder.origin[0].city && this.DataOrder.pod.city == this.DataOrder.destination[0].city)
              this.filterRoute = 2

            if (this.DataOrder.pol.city == this.DataOrder.origin[0].city && this.DataOrder.pod.city != this.DataOrder.destination[0].city)
              this.filterRoute = 3

            if (this.DataOrder.pol.city!=this.DataOrder.origin[0].city && this.DataOrder.pod.city == this.DataOrder.destination[0].city)
              this.filterRoute = 1

            if (this.DataOrder.pol.city!=this.DataOrder.origin[0].city && this.DataOrder.pod.city != this.DataOrder.destination[0].city)
              this.filterRoute = 4

          }
          /*

          else if (this.DataOrder.pol == null && this.DataOrder.pod == null)
            this.filterRoute = 4;*/

        /*  this.$nextTick(() => {
            if (this.showComponent == 1) {
              const pol = data.data.pol
              const pod = data.data.pod
                this.$refs['originAutocomplete'].searchPlaces(origin.address).then(() => {
                  let originSelected = this.originItems[0]
                  originSelected.selected = true;
                  if (pol) {
                    let portSelected = originSelected.ports.findIndex(x => x.name == pol.name)
                    if (portSelected !== -1)
                      originSelected.ports[portSelected].selected = true
                    else
                      throw new Error('Port not found in results')
                  }

                  this.origin = originSelected
                  this.originSelected(this.origin)
                }).finally(() => {
                  this.$refs['destinationAutocomplete'].searchPlaces(destination.address).then(() => {
                    let destinationSelected = this.destinationItems[0]
                    destinationSelected.selected = true;
                    if (pod) {
                      let portSelected = destinationSelected.ports.findIndex(x => x.name === pod.name)
                      if (portSelected !== -1)
                        destinationSelected.ports[portSelected].selected = true
                      else
                        throw new Error('Port not found in results')
                    }

                    this.destination = destinationSelected
                    this.destinationSelected(this.destination)
                  })
                })

            }
            if (this.showComponent == 2) {
              const aol = data.data.pol
              const aod = data.data.pod
                this.$refs['originAutocomplete'].searchPlaces(origin.address).then(() => {
                  let originSelected = this.originItems[0]
                  console.log(originSelected)
                  originSelected.selected = true
                  if (aol) {
                    let portSelected = originSelected.airports.findIndex(x => x.name == aol.name)
                    if (portSelected !== -1)
                      originSelected.airports[portSelected].selected = true
                    else
                      throw new Error('AirPort not found in results')
                  }

                  this.origin = originSelected
                  this.originSelectedAereo(this.origin)
                }).finally(() => {
                  this.$refs['destinationAutocomplete'].searchPlaces(destination.address).then(() => {
                    let destinationSelected = this.destinationItems[0]
                    destinationSelected.selected = true
                    if (aod) {
                      let portSelected = destinationSelected.airports.findIndex(x => x.name === aod.name)
                      if (portSelected !== -1)
                        destinationSelected.airports[portSelected].selected = true
                      else
                        throw new Error('Port not found in results')
                    }

                    this.destination = destinationSelected
                    this.destinationSelectedAereo(this.destination)
                  })
                })

            }
            if (this.showComponent == 3) {

              this.$refs['originAutocomplete'].searchPlaces(origin.address).then(() => {
                let originSelected = this.originItems[0]
                originSelected.selected = true;
                this.origin = originSelected
                this.originSelectedTerrestre(this.origin)
              }).finally(() => {
                this.$refs['destinationAutocomplete'].searchPlaces(destination.address).then(() => {
                  let destinationSelected = this.destinationItems[0]
                  destinationSelected.selected = true;
                  this.destination = destinationSelected
                  this.destinationSelectedTerrestre(this.destination)
                })
              })

            }

          })*/
        }
        this.GetDataService();


        if (data.code == 400 || data.code == 500) {
          this.$toast.error("Algo salio mal, intente mas tarde")
        }
      }).catch(error => {
        console.error(error);


      }).finally(() => {
        this.$store.dispatch('working', false);
      })

    },
    closeModal() {
      this.$emit('UpdateOrder');
    },
    getPricer: function () {
      this.$store.dispatch('working', true)

      this.$http.get('Employees/Pricing')
          .then(response => {

            this.Pricers = response.data.data;

          })
          .catch(() => {

            // console.log(error)

          }).finally(() => this.$store.dispatch('working', false))
    },
    getSalesManager: function () {
      this.$store.dispatch('working', true)

      this.$http.get('Employees/Salesmanager')
          .then(response => {

            this.SalesManagers = response.data.data;

          })
          .catch(() => {

            // console.log(error)

          }).finally(() => this.$store.dispatch('working', false))
    },
    getTransport: function () {

      this.$http.get('Transportation')
          .then(response => {
            this.Transport = response.data.data;
          }).catch(() => {
        // console.log(error)
        this.errored = true
      }).finally(() => this.loading = false)

    },
    updateOriginAddress(text) {
      this.originAddress = text
    },
    updateOriginItems(items) {
      // console.log(items)
      this.originItems = items;
    },
    updateDestinationItems(items) {
      this.destinationItems = items;
    },
    updateDestinationAddress(text) {
      this.destinationAddress = text
    },
    originSelected(selections) {
      try {
        // console.log(selections)
        this.origin = selections
        this.originAddress = selections.description
        this.$nextTick(() => {
          try {
            if (!Array.isArray(this.origin)) {
              // console.log("Origin", this.origin.details);

              this.QuotData.origin["country"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
              this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name??this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
              this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
                  a === "sublocality" ||
                  a === "administrative_area_level_3" ||
                  a === 'neighborhood' ||
                  a === "administrative_area_level_2") !== -1)[0]?.long_name
              this.QuotData.origin["coordinates"] = this.origin.details.location;
              this.QuotData.origin["ports"] = this.origin.ports;
              this.QuotData.origin["address"] = this.origin.description;
              this.QuotData.AutocompleteOrigin = true;
              let index = this.origin.ports.findIndex(e => e.selected);
              this.QuotData.origin["ports"] = this.origin.ports;
              // console.log(index)

              this.origin.address = this.originSelections.description;
              if (index !== -1) {
                this.QuotData.OriginPort = this.origin.ports[index];
                this.QuotData.origin["ports"].name = this.origin.ports[index].name;
                this.QuotData.origin["ports"].city = this.origin.ports[index].city;
                this.origin.ports[index].coordinates[0] = parseInt(this.origin.ports[index].coordinates[0]);
                this.origin.ports[index].coordinates[1] = parseInt(this.origin.ports[index].coordinates[1]);
                this.QuotData.origin["ports"].coordinates = `${this.origin.ports[index].coordinates[0]},${this.origin.ports[index].coordinates[1]}`;
                this.QuotData.origin["ports"].country = this.origin.ports[index].country;

                this.originAddress = `${this.QuotData.origin["ports"].name}, ${this.QuotData.origin["ports"].city}, ${this.QuotData.origin["ports"].country}`
              }
            }
          } catch (e) {
            this.QuotData.origin = {}
            throw new Error(`There was an error while selecting the item ${JSON.stringify(selections)} \n` + e)
          }
        })
      } catch (error) {
        // console.log(error)
        this.QuotData.origin = {}
        throw new Error(`There was an error while selecting a new origin ${JSON.stringify(selections)} \n` + error)
      }
    },
    originSelectedTerrestre(selections) {
      try {
        // console.log(selections)
        this.origin = selections
        this.originAddress = selections.description
        this.$nextTick(() => {
          try {
            if (!Array.isArray(this.origin)) {
              // console.log("Origin", this.origin.details);

              this.QuotData.origin["country"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
              this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name??this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
              this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
                  a === "sublocality" ||
                  a === "administrative_area_level_3" ||
                  a === 'neighborhood' ||
                  a === "administrative_area_level_2") !== -1)[0]?.long_name
              this.QuotData.origin["coordinates"] = this.origin.details.location;

              this.QuotData.origin["address"] = this.origin.description;
              this.QuotData.AutocompleteOrigin = true;
              // console.log(index)

              this.origin.address = this.originSelections.description;

            }
          } catch (e) {
            this.QuotData.origin = {}
            throw new Error(`There was an error while selecting the item ${JSON.stringify(selections)} \n` + e)
          }
        })
      } catch (error) {
        // console.log(error)
        this.QuotData.origin = {}
        throw new Error(`There was an error while selecting a new origin ${JSON.stringify(selections)} \n` + error)
      }
    },
    destinationSelected(selections) {
      this.destination = selections
      this.destinationAddress = selections.description
      this.$nextTick(() => {
        try {
          if (!Array.isArray(this.destination)) {
            this.QuotData.destination["country"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
            this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name??this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
            this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
              a === "sublocality" ||
              a === "administrative_area_level_3" ||
              a === 'neighborhood' ||
              a === "administrative_area_level_2") !== -1)[0]?.long_name
            this.QuotData.destination["coordinates"] = this.destination.details.location
            this.QuotData.destination["ports"] = this.destination.ports;
            this.QuotData.AutocompleteDestination = true;
            this.QuotData.destination["address"] = this.destination.description;

            let index = this.destination.ports.findIndex(e => e.selected);
            this.QuotData.destination["ports"] = this.destination.ports;

            // this.Origin.address = this.originSelections.description;
            if (index !== -1) {
              this.QuotData.DestinationPort = this.destination.ports[index];
              this.QuotData.destination["ports"].name = this.destination.ports[index].name;
              this.QuotData.destination["ports"].city = this.destination.ports[index].city;
              this.destination.ports[index].coordinates[0] = parseInt(this.destination.ports[index].coordinates[0]);
              this.destination.ports[index].coordinates[1] = parseInt(this.destination.ports[index].coordinates[1]);
              this.QuotData.destination["ports"].coordinates = `${this.destination.ports[index].coordinates[0]},${this.destination.ports[index].coordinates[1]}`;
              this.QuotData.destination["ports"].country = this.destination.ports[index].country;

              this.destinationAddress = `${this.QuotData.destination["ports"].name}, ${this.QuotData.destination["ports"].city}, ${this.QuotData.destination["ports"].country}`
            }
          }
        } catch (e) {
          this.QuotData.destination = {}
          throw new Error(`There was an error while selecting the item ${JSON.stringify(selections)} \n` + e)
        }
      })
    },
    destinationSelectedTerrestre(selections) {
      this.destination = selections
      this.destinationAddress = selections.description
      this.$nextTick(() => {
        try {
          if (!Array.isArray(this.destination)) {
            this.QuotData.destination["country"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
            this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name??this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
            this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
              a === "sublocality" ||
              a === "administrative_area_level_3" ||
              a === 'neighborhood' ||
              a === "administrative_area_level_2") !== -1)[0]?.long_name
            this.QuotData.destination["coordinates"] = this.destination.details.location
            this.QuotData.AutocompleteDestination = true;
            this.QuotData.destination["address"] = this.destination.description;


            // this.Origin.address = this.originSelections.description;

          }
        } catch (e) {
          this.QuotData.destination = {}
          throw new Error(`There was an error while selecting the item ${JSON.stringify(selections)} \n` + e)
        }
      })
    },






    originSelectedAereo(selections) {
      try {
        // console.log(selections)
        this.origin = selections
        this.originAddress = selections.description
        this.$nextTick(() => {
          try {
            if (!Array.isArray(this.origin)) {
              this.QuotData.origin["country"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
              this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name??this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
              this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
              a === "sublocality" ||
              a === "administrative_area_level_3" ||
              a === 'neighborhood' ||
              a === "administrative_area_level_2") !== -1)[0]?.long_name
              this.QuotData.origin["coordinates"] = this.origin.details.location;
              this.QuotData.origin["airports"] = this.origin.airports;
              this.QuotData.origin["address"] = this.origin.description;
              this.QuotData.AutocompleteOrigin = true;
              let index = this.origin.airports.findIndex(e => e.selected);
              if (index !== -1) {
                this.QuotData.origin["airports"].name = this.origin.airports[index].name;
                this.QuotData.origin["airports"].city = this.origin.airports[index].city;
                this.QuotData.origin["airports"].coordinates = `${this.origin.airports[index].lat},${this.origin.airports[index].lon[1]}`;
                this.QuotData.origin["airports"].country = this.origin.airports[index].country;
                this.QuotData.OriginPort = this.origin.airports[index];
                this.originAddress = `${this.QuotData.origin["airports"].name}, ${this.QuotData.origin["airports"].city}, ${this.QuotData.origin["airports"].country}`
              }
            }
          } catch (e) {
            this.QuotData.origin = {}
            throw new Error(`There was an error while selecting the item ${JSON.stringify(selections)} \n` + e)
          }
        })
      } catch (error) {
        this.QuotData.origin = {}
        console.log(error)
      }
    },
    destinationSelectedAereo(selections) {
      try {
        this.destination = selections
        this.destinationAddress = selections.description
        this.$nextTick(() => {
          try {
            if (!Array.isArray(this.destination)) {
              this.QuotData.destination["country"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
              this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name??this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
              this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
              a === "sublocality" ||
              a === "administrative_area_level_3" ||
              a === 'neighborhood' ||
              a === "administrative_area_level_2") !== -1)[0]?.long_name
              this.QuotData.destination["coordinates"] = this.destination.details.location;
              this.QuotData.destination["airports"] = this.destination.airports;
              this.QuotData.destination["address"] = this.destination.description;
              this.QuotData.AutocompleteOrigin = true;
              let index = this.destination.airports.findIndex(e => e.selected);
              if (index !== -1) {
                this.QuotData.destination["airports"].name = this.destination.airports[index].name;
                this.QuotData.destination["airports"].city = this.destination.airports[index].city;
                this.QuotData.destination["airports"].coordinates = `${this.destination.airports[index].lat},${this.destination.airports[index].lon[1]}`;
                this.QuotData.destination["airports"].country = this.destination.airports[index].country;
                this.QuotData.DestinationPort = this.destination.airports[index];
                this.destinationAddress = `${this.QuotData.destination["airports"].name}, ${this.QuotData.destination["airports"].city}, ${this.QuotData.destination["airports"].country}`
              }
            }
          } catch (e) {
            this.QuotData.destination = {}
            throw new Error(`There was an error while selecting the item ${JSON.stringify(selections)} \n` + e)
          }
        })
      } catch (error) {
        this.QuotData.destination = {}
        console.log(error)
      }
    },
    GetDataService() {
      if (this.DataOrder.transportationId === 1) {
        this.QuotData.Services = {
          Almacenaje: 0,
          Seguro: 0,
          Embalaje: 0,
          Financiamiento: 0,
          Recoleccion: 0,
          DespachoEnOrigen: 0,
          GastosPortuariosOrigen: 0,
          FleteMaritimo: 0,
          GastosPortuariosDestino: 0,
          DespachoDeImportacion: 0,
          Entrega: 0,
          AgenteAduanal: 0,
          Almacenamiento: 0,
          CustodiaArmada: 0,
          Paletizadora: 0,
          Inspeccion: 0,
          Maniobras: 0,
          SeguroMercancias: 0,
          resulImage: ""
        }
      }
      if (this.DataOrder.transportationId === 2) {
        this.QuotData.Services = {
          Almacenaje: 0,
          Seguro: 0,
          Embalaje: 0,
          Financiamiento: 0,
          Recoleccion: 0,
          DespachoEnOrigen: 0,
          GastosPortuariosOrigen: 0,
          FleteAereo: 0,
          GastosPortuariosDestino: 0,
          DespachoDeImportacion: 0,
          SeguroMercancias: 0,
          Entrega: 0,
          AgenteAduanal: 0,
          Almacenamiento: 0,
          CustodiaArmada: 0,
          Paletizadora: 0,
          Inspeccion: 0,
          Maniobras: 0,
          resulImage: ""
        }
      }
      if (this.DataOrder.transportationId === 3) {
        this.QuotData.Services = {
          Almacenaje: 0,
          Seguro: 0,
          Embalaje: 0,
          Financiamiento: 0,
          Recoleccion: 0,
          DespachoEnOrigen: 0,
          GastosPortuariosOrigen: 0,
          Flete: 0,
          GastosPortuariosDestino: 0,
          DespachoDeImportacion: 0,
          Entrega: 0,
          AgenteAduanal: 0,
          Almacenamiento: 0,
          CustodiaArmada: 0,
          Paletizadora: 0,
          Inspeccion: 0,
          Maniobras: 0,
          SeguroMercancias: 0,
          resulImage: ""
        }
      }

      this.DataOrder.services.forEach(element => {
        // Maritimo
        if (this.DataOrder.transportationId == 1) {
          // console.log("Map Servicios Maritimo")
          if (element.serviceId == 14) {
            this.QuotData.Services.Financiamiento = '1';
          }

          if (element.serviceId == 8) {
            this.QuotData.Services.SeguroMercancias = '1';
          }

          if (element.serviceId == 16) {
            this.QuotData.Services.Almacenamiento = '1'
          }

          if (element.serviceId == 1) {
            this.QuotData.Services.Embalaje = '1';
          }

          if (element.serviceId == 2) {
            this.QuotData.Services.Recoleccion = '1';
          }

          if (element.serviceId == 3) {
            this.QuotData.Services.DespachoEnOrigen = '1';
          }

          if (element.serviceId == 4)
            this.QuotData.Services.GastosPortuariosOrigen = '1'

          if (element.serviceId == 5) {
            this.QuotData.Services.FleteMaritimo = '1';
          }

          if (element.serviceId == 10)
            this.QuotData.Services.GastosPortuariosDestino = '1'

          if (element.serviceId == 12)
            this.QuotData.Services.DespachoDeImportacion = '1'

          if (element.serviceId == 13)
            this.QuotData.Services.Entrega = '1'

          if (element.serviceId == 18)
            this.QuotData.Services.Paletizadora = true

          if (element.serviceId == 20)
            this.QuotData.Services.Maniobras = true

          if (element.serviceId == 19)
            this.QuotData.Services.Inspeccion = true

          if (element.serviceId == 15)
            this.QuotData.Services.AgenteAduanal = true

          if (element.serviceId == 17)
            this.QuotData.Services.CustodiaArmada = true
        }
        // Aviones... de m
        if (this.DataOrder.transportationId == 2) {
          // console.log("Map Servicios Aereo")

          if (element.serviceId == 14) {
            this.QuotData.Services.Financiamiento = '1';
          }

          if (element.serviceId == 8) {
            this.QuotData.Services.SeguroMercancias = '1';
          }

          if (element.serviceId == 16) {
            this.QuotData.Services.Almacenamiento = '1'
          }

          if (element.serviceId == 1) {
            this.QuotData.Services.Embalaje = '1';
          }

          if (element.serviceId == 2) {
            this.QuotData.Services.Recoleccion = '1';
          }

          if (element.serviceId == 3) {
            this.QuotData.Services.DespachoEnOrigen = '1';
          }

          if (element.serviceId == 4)
            this.QuotData.Services.GastosPortuariosOrigen = '1'

          if (element.serviceId == 6) {
            this.QuotData.Services.FleteAereo = '1';
          }

          if (element.serviceId == 10)
            this.QuotData.Services.GastosPortuariosDestino = '1'

          if (element.serviceId == 12)
            this.QuotData.Services.DespachoDeImportacion = '1'

          if (element.serviceId == 13)
            this.QuotData.Services.Entrega = '1'

          if (element.serviceId == 18)
            this.QuotData.Services.Paletizadora = true

          if (element.serviceId == 20)
            this.QuotData.Services.Maniobras = true

          if (element.serviceId == 19)
            this.QuotData.Services.Inspeccion = true

          if (element.serviceId == 15)
            this.QuotData.Services.AgenteAduanal = true

          if (element.serviceId == 17)
            this.QuotData.Services.CustodiaArmada = true
        }
        // Terrestre
        if (this.DataOrder.transportationId == 3) {
          if (element.serviceId == 14) {
            this.QuotData.Services.Financiamiento = '1';
          }

          if (element.serviceId == 8) {
            this.QuotData.Services.SeguroMercancias = '1';
          }

          if (element.serviceId == 16) {
            this.QuotData.Services.Almacenamiento = '1'
          }

          if (element.serviceId == 1) {
            this.QuotData.Services.Embalaje = '1';
          }

          if (element.serviceId == 2) {
            this.QuotData.Services.Recoleccion = '1';
          }

          if (element.serviceId == 3) {
            this.QuotData.Services.DespachoEnOrigen = '1';
          }

          if (element.serviceId == 4)
            this.QuotData.Services.GastosPortuariosOrigen = '1'

          if (element.serviceId == 7) {
            this.QuotData.Services.FleteTerrestre = '1';
          }

          if (element.serviceId == 10)
            this.QuotData.Services.GastosPortuariosDestino = '1'

          if (element.serviceId == 12)
            this.QuotData.Services.DespachoDeImportacion = '1'

          if (element.serviceId == 13)
            this.QuotData.Services.Entrega = '1'

          if (element.serviceId == 18)
            this.QuotData.Services.Paletizadora = true

          if (element.serviceId == 20)
            this.QuotData.Services.Maniobras = true

          if (element.serviceId == 19)
            this.QuotData.Services.Inspeccion = true

          if (element.serviceId == 15)
            this.QuotData.Services.AgenteAduanal = true

          if (element.serviceId == 17)
            this.QuotData.Services.CustodiaArmada = true

        }


      })
      // console.log("Parsed Services",this.QuotData)
      // console.log(this.dataService);
    },
    getCustomers: function () {
      this.$store.dispatch('working', true)

      this.$http.get('Companies')
          .then(response => {
            // console.log(response)
            this.Customers = response.data.data;
          })
          .catch(error => {

            console.log(error)

          }).finally(() => this.$store.dispatch('working', false))
    },
    getIncoterms: function () {
      this.$http.get('Incoterms')
          .then(response => {
            this.Incoterms = response.data.data;
          })
          .catch(() => {
            // console.log(error);
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    setTypeCargo: function () {
      this.CargoType.forEach((element) => {
        if (element.Description == this.DataOrder.cargo[0].type) {
          this.DataOrder.cargo[0].loadingTypeId = element.Id;

        }
      })
    },

    RecibeData: function (data) {
       console.log("Data Rec", data)
      this.QuotData.Typetransportation = data.transport;
      this.QuotData.TypeService = data.service;
      this.QuotData.Inconterm = data.incoterm;
      this.QuotData.filterRoute = data.filterRoute;
      this.QuotData.Type = data.typeRoute;
      this.QuotData.dueDate = data.dueDate;
      this.QuotData.AutocompleteOrigin = true;
      this.QuotData.DataService = data.DataService;
      // this.QuotData.cargo = data.cargo;


      if (this.origin.description) {
        // console.log(this.origin)
        this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3") !== -1)[0].long_name
        this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
        this.QuotData.origin["country"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        this.QuotData.origin["coordinates"] = this.origin.details.location
        this.QuotData.AutocompleteOrigin = true;
      }

      if (this.destination.description) {
        // console.log(this.destination)
        this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3") !== -1)[0].long_name
        this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
        this.QuotData.destination["country"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        this.QuotData.destination["coordinates"] = this.destination.details.location
        this.QuotData.AutocompleteDestination = true;
      }

      // console.log(this.QuotData)

    },
    setQuoteData() {

      console.log("Original Data", this.DataOrder)
      this.QuotData.Typetransportation = this.DataOrder.transportationId;
      this.QuotData.TypeService = this.DataOrder.transportationCategoryId;
      this.QuotData.Inconterm = this.DataOrder.incotermId;
      this.QuotData.Type = this.DataOrder.orderTypeId;
      this.QuotData.filterRoute = this.DataOrder.deliveryTypeId;
      this.QuotData.dueDate = this.DataOrder.departureDate;
      this.QuotData.dueDate = this.moment(this.QuotData.dueDate).format('YYYY-MM-DD');
      // this.QuotData.DataService = this.DataOrder.cargo;
      console.log('cargo', this.DataOrder.cargo)
      this.QuotData.cargo = this.DataOrder.cargo;

      this.originItems = this.DataOrder.origin;
      this.destinationItems = this.DataOrder.destination;
      this.QuotData.AutocompleteOrigin = true;
      if (this.origin.description) {
        this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3") !== -1)[0].long_name
        this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
        this.QuotData.origin["country"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        this.QuotData.origin["coordinates"] = this.origin.details.location
        this.QuotData.AutocompleteOrigin = true;
      }
      this.QuotData.AutocompleteDestination = true;
      if (this.destination.description) {
        this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3") !== -1)[0].long_name
        this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
        this.QuotData.destination["country"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        this.QuotData.destination["coordinates"] = this.destination.details.location
        this.QuotData.AutocompleteDestination = true;
      }


    },





    UpdateOrder(dataSend) {
      console.log(dataSend)
      if(dataSend.origin[0].city == null){
        dataSend.origin[0] = this.defaultOrigin
      }
      console.log('anteselimino', dataSend)
      dataSend.pol = null
      dataSend.pod = null
      console.log('despueselimino', dataSend)
      /*if(dataSend.pol == null){
        dataSend.pol = this.defaultPol
      }
      if(dataSend.pod == null){
        dataSend.pod = this.defaultPod
      }*/
      if(dataSend.destination[0].city == null){
        dataSend.destination[0] = this.defaultDestination
      }
      let cargoDocuments;
      dataSend.cargo.forEach(element => {
        if (!element.dangerous) {
          delete element.documents
        }
        if(element.IMO && element.UN){
          cargoDocuments = [element.IMO, element.UN]
          element.documents.forEach(data => {
            data.file = null
          })
        }

        if (element.type == "Container"){
          element.loadingTypeId = null
          // element.height = 0;
          // element.large = 0;
          // element.width = 0;
        }

        if (element.type=="Pallets"){
          element.containerId = null;
          element.loadingTypeId = 2;
        }
      });

      this.$store.dispatch('working', true)
      console.log(dataSend)
      let toSend = JSON.parse(JSON.stringify(dataSend))
      console.log('despuesdeparse', toSend)
      if (toSend.transportationCategory) {
        toSend.transportationCategory.transportation = toSend.transportation
      }

  /*    if (this.showComponent === 3) {
        toSend.pod = null;
        toSend.pol = null;
      }*/
      delete toSend.Services

      if (toSend.services.length === 0) {
        delete toSend.services
      }
      if(toSend.history){
        delete toSend.history
        /*toSend.history.forEach((element) =>{
          if (!element.user.userConnection){
            delete element.user.userConnection
            delete element.user.configuration
          }
        })*/
      }

      console.log(toSend)
      this.$http.put(`Orders/${this.Edit.idOrder}`, toSend).then(response => {
        console.log(response.data.code)
        if (response.data.code === 200) {
          console.log(response.data.data);
          response.data.data.cargo.forEach(cargo => {
                  cargo.documents.forEach(docResponse => {
                    cargoDocuments.forEach(async doc => {
                      if (doc.name.substring(0, doc.name.indexOf(".")) === docResponse.fileName) {
                        await getS3(docResponse.path, doc).catch(() => {
                          this.$toast.error("Ocurrio un error al subir el archivo")
                        })
                      }
                    })
                  })
                })
              dataSend.documentsDrag.forEach(doc => {
                response.data.data.documents.forEach( async docResponse => {
                  if (doc.name.substring(0, doc.name.indexOf(".")) === docResponse.fileName) {
                    await getS3(docResponse.path, doc).catch(() => {
                      this.$toast.error("Ocurrio un error al subir el archivo")
                    })
                  }
                })
              })
          this.$toast.info("Cotizacion Actualizada");
          this.$emit('OrderUpdated');
          // this.$refs.loadDetail.resetCargo();
          //this.SetDefaultOrder();
        } else {
          this.$toast.error("¡Ups! No pasó tu cotización. Nuestro equipo ya fue notificadoa")
        }
      }).catch(error => {
        this.$toast.error("¡Ups! No pasó tu cotización. Nuestro equipo ya fue notificadoa")
        console.log(error);
      }).finally(() => {
        this.closeModal()
        this.$store.dispatch('working', false)
      })



      // this.$http.put(`Orders/${this.id}`, this.DataOrder).then(() => {
      //   // console.log(response.data.data);
      //   this.$toast.info("Empleado Actualizado");
      // }).catch(() => {
      //   // console.log(error);
      //   this.errored = true
      // }).finally(() => this.$store.dispatch('working', false))
    }
  },


  mounted() {
    this.GetDataOrder();
    this.getPricer();
    this.getIncoterms();
    this.getCustomers();
    this.getTransport();
    this.getSalesManager();
  }
}
</script>

<style scoped lang="scss">

.fixedOption {
  position: fixed;
  z-index: 2;
  bottom: 1em;
  right: 2em;
}

.border-rounded {
  border-radius: 10px;
}

.fixed-col-size {
  min-width: 250px;
}

.searchButton {
  z-index: 2;
}

.position-relative {
  position: relative;
}

.notification {
  cursor: pointer;
}

> > > .container {
  min-width: 100%;
}

</style>

