<template>
  <v-row class="mt-4">
    <v-col :cols="esVendedor ? '12' : '8'">
      <v-row v-if="shipment.sale" class="mr-5 mb-1" style="justify-content: right;">
        <h4 class=" mr-2">Compra Total (USD): ${{ shipment.totalPurchaseUsd.toFixed(2) }}</h4>
        <!--        <h4 class="ml-2" :style="shipment.sale.totalSale >= shipment.totalPurchase ? 'color:green' : 'color:red'"> Venta Total: {{shipment.sale.totalSale}}$</h4>-->
      </v-row>
      <v-expansion-panels v-model="panel" class="pl-4" popout>
        <v-expansion-panel v-for="(provedor, detailIndex) in prices.details" :key="detailIndex" class="mt-2">
          <v-expansion-panel-header class="ml-0 pl-2" expand-icon="mdi-menu-down"
                                    @click="getSolicitudes(provedor, detailIndex)">
            <v-row class="ml-2 mt-1">
              <v-col cols="4 mr-2">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-autocomplete v-model="provedor.freightForwarderId"
                                  :disabled="!provedor.isEditable || provedor.feesConfirmation"
                                  :items="forwarders" dense item-text="companyName"
                                  item-value="id"
                                  no-filter
                                  :loading="loadingForwarders"
                                  label="Agente en origen" @click.native.stop
                                  @update:search-input="findForwarder($event)"
                  ></v-autocomplete>
                </v-form>
              </v-col>
              <v-col cols="4">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-autocomplete v-model="provedor.supplierId"
                                  :disabled="!provedor.isEditable || provedor.feesConfirmation"
                                  :items="itemsSuppliers" :rules="selectRule" dense item-text="companyName"
                                  item-value="id"
                                  label="Provedor" @click.native.stop></v-autocomplete>
                </v-form>
              </v-col>
              <v-col class="mt-0 pt-0" cols="3">
                <v-text-field v-model="provedor.freeDaysDestination"
                              :disabled="!provedor.isEditable || provedor.feesConfirmation"
                              class="mt-0 pt-0" label="Free days destination"
                              @click.native.stop></v-text-field>
              </v-col>
            </v-row>
            <v-row class="justify-end mt-0">
              <!-- sino se agregar el @click.native.stop al momento de dar click tambien se abrira/cerrara el expansion panel! -Quintero -->
              <div v-if="provedor.validity" class="text--primary">
                <div class="text-center mt-3">
                  Validity: {{ provedor.validity.slice(0, 10) }}
                </div>
              </div>
              <div class="text-center">
                <div class="text-center">
                  <v-menu v-model="menuInfo2" :close-on-content-click="false" :nudge-width="200" offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" icon v-bind="attrs" @click="filterOrders" v-on="on" @click.native.stop>
                        <v-icon> mdi-information-outline</v-icon>
                      </v-btn>
                    </template>

                    <v-card>
                      <v-row>
                        <v-col cols="12">
                          <v-card-title>Consolidar Compra</v-card-title>
                          <v-divider></v-divider>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-card-text>
                            <v-select v-model="Shp" :item-text="item => item.refCode" dense
                                      item-value="id"
                                      label="Embarques asociado" multiple>
                            </v-select>
                          </v-card-text>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="menuInfo2 = false">
                              Cerrar
                            </v-btn>
                            <v-btn color="primary" text @click="asociar">
                              Guardar
                            </v-btn>
                          </v-card-actions>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-menu>
                </div>
              </div>
              <div class="text-center">
                <div class="text-center">
                  <v-menu v-model="menuInfo" :close-on-content-click="false" :nudge-width="200" offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" icon v-bind="attrs" v-on="on" @click.native.stop>
                        <v-icon> mdi-information-outline</v-icon>
                      </v-btn>
                    </template>

                    <v-card>
                      <v-row>
                        <v-col cols="12">
                          <v-card-title>Numero de referencia: {{ provedor.referenceNumber }}</v-card-title>
                          <v-divider></v-divider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <div class="text--primary ml-4 mt-4 mr-4">
                            <v-text-field v-model="provedor.transportationTime"
                                          label="Transportation time"></v-text-field>
                          </div>
                          <div class="text--primary ml-4 mr-4">
                            <v-menu :ref="'menu' + detailIndex" v-model="provedor.menu" :close-on-content-click="false"
                                    :return-value.sync="provedor.departureTime" min-width="auto" offset-y
                                    transition="scale-transition">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="provedor.departureTime" append-icon="mdi-calendar"
                                              label="Departure time" readonly v-bind="attrs"
                                              v-on="on"></v-text-field>
                              </template>
                              <v-date-picker v-model="provedor.departureTime" color="primary" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="provedor.menu = false">
                                  Cancel
                                </v-btn>
                                <v-btn color="primary" text
                                       @click="$refs['menu' + detailIndex][0].save(provedor.departureTime)">
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </div>
                          <div class="text--primary ml-4  mr-4">
                            <v-text-field v-model="provedor.serviceId" label="Service id"></v-text-field>
                          </div>
                          <div class="text--primary ml-4 mr-4">
                            <v-text-field v-model="provedor.freeDaysOrigin" label="Free days origin"></v-text-field>
                          </div>
                        </v-col>
                        <v-col>
                          <div class="text--primary ml-4 mr-4">
                            <v-text-field v-model="provedor.transportationId" label="Transportation id"></v-text-field>
                          </div>
                          <div class="text--primary ml-4 mr-4">
                            <v-text-field v-model="provedor.originStorage" label="Origin storage"></v-text-field>
                            <div class="text--primary ml-4 mr-4">
                              <v-checkbox v-model="provedor.hasTranfer" label="Has transfer"></v-checkbox>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-divider></v-divider>
                          <v-card-text>
                            <v-select v-model="select" :items="items5" item-text="state" item-value="abbr"
                                      label="Select"
                                      persistent-hint return-object single-line></v-select>
                          </v-card-text>
                        </v-col>
                        <v-col cols="12">
                          <v-divider></v-divider>
                          <v-card-text>
                            <v-select :item-text="item => item.firstName + ' ' + item.lastName" class="mt-4"
                                      dense
                                      item-value="id" label="Empleados" multiple>
                            </v-select>
                          </v-card-text>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="menuInfo = false">
                              Cerrar
                            </v-btn>
                            <v-btn color="primary" text @click="actualizarCompra(provedor)">
                              Guardar
                            </v-btn>
                          </v-card-actions>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-menu>
                </div>
              </div>
              <v-checkbox v-model="provedor.feesConfirmation" :disabled="provedor.feesConfirmation" class="mt-2 pt-0"
                          label="Confirmar Precios" @click="confirmPrice2(provedor.id)"
                          @click.native.stop></v-checkbox>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn :disabled="!provedor.isEditable || provedor.feesConfirmation || !isPurchaseValid
                    " color="primary" icon @click="actualizarCompra(provedor)" v-on="on" @click.native.stop>
                    <v-icon> mdi-content-save</v-icon>
                  </v-btn>
                </template>
                <span>Salvar cambios</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn class="mt-0" color="primary" icon @click="openDialog1(provedor)" v-on="on" @click.native.stop
                         :disabled="(provedor.isEditable && !provedor.feesConfirmation) || shipment.shippingStatusId === 9">
                    <v-icon> mdi-table-edit</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-menu offset-y>
                <template v-slot:activator="{ on: menu }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip, attrs }">
                      <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                        <v-icon> mdi-file-document</v-icon>
                      </v-btn>
                    </template>
                    <span>Documentos</span>
                  </v-tooltip>
                </template>
                <v-card flat tile width="500">
                  <v-card-title class="heading">
                    <v-list-item dense>
                      <v-list-item-content>
                        Documentos
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-title>
                  <v-card-text>
                    <span> </span>
                    <v-virtual-scroll :items="provedor.documents" height="300" item-height="64">
                      <template v-slot:default="{ item }">
                        <v-list-item :key="item" dense @click="downloadDocument(item)">
                          <v-list-item-content>
                            <v-list-item-title class="text-wrap">
                              {{ item.fileName }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-virtual-scroll>
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn v-if="!prices.feesConfirmation" class="ml-2" color="error" icon @click="operDialogD(provedor)"
                         v-on="on"
                         @click.native.stop
                         :disabled="shipment.shippingStatusId === 9">
                    <v-icon> mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar provedor</span>
              </v-tooltip>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card :disabled="shipment.shippingStatusId === 9">
              <v-container class="overflow-auto" style="max-height: 400px">
                <v-data-table :headers="headers" :hide-default-footer="true" :items="provedor.fees"
                              :items-per-page="200"
                              :show-select="true" dense @current-items="setFirstLast">
                  <template v-slot:item.data-table-select="{ item }">
                    <v-checkbox v-model="item.selected" :disabled="item.disable || !provedor.feesConfirmation"
                                @click="send2Summary(item, provedor)"></v-checkbox>
                  </template>
                  <template v-slot:item.name="{ item }">
                    <v-combobox
                        v-model="item.purchaseConcept"
                        dense
                        label="Editando nombre"
                        single-line
                        :items="globalConcepts"
                        :disabled="item.disable"
                        :loading="item.searchConceptLoading"
                        @update:search-input="updateConceptSearch($event, item)"
                        @change="onChangePurchaseFeeConcept($event, item)"
                        item-value="id"
                        item-text="name"
                        no-filter
                        clearable
                        return-object
                    >
                    </v-combobox>
                  </template>
                  <template v-slot:item.ratableWeight="{ item }">
                    <v-text-field v-model="item.ratableWeight" :disabled="provedor.feesConfirmation || item.disable"
                                  :rules="[numberInputValidation]"
                                  autofocus label="Editando Ratable Weight"
                                  single-line
                                  type="number"></v-text-field>
                  </template>
                  <template v-slot:item.quantity="{ item }">
                    <v-text-field v-model="item.quantity" :disabled="provedor.feesConfirmation || item.disable"
                                  :rules="[numberInputValidation]" autofocus
                                  label="Editando Cantidad" single-line
                                  type="number"></v-text-field>
                  </template>
                  <template v-slot:item.price="{ item }">
                    <v-text-field v-model.number="item.price" :disabled="provedor.feesConfirmation || item.disable"
                                  :rules="[numberInputValidation]"
                                  autofocus
                                  label="Editando Compra" single-line
                                  type="number"></v-text-field>
                  </template>
                  <template v-slot:item.compraTotal="{ item }">
                    <div v-if="shipment.transportationId === 1 || shipment.transportationId === 3">
                      <div v-if="shipment.transportationCategoryId === 9">
                        {{ item.total }}
                      </div>
                      <div v-else>
                        {{ item.total }}
                      </div>
                    </div>
                    <div v-else>
                      {{ item.total }}
                    </div>
                  </template>
                  <template v-slot:item.currency.code="{ item }">
                    <v-select v-model="item.currencyId" :disabled="provedor.feesConfirmation || item.disable"
                              :items="currencies2" item-text="code" @change="changeCurrency(item)"
                              item-value="id"></v-select>
                  </template>
                  <template v-slot:item.hasVat="{ item }">
                    <v-checkbox v-model="item.hasVat" color="primary" disabled></v-checkbox>
                  </template>
                  <template v-slot:item.hasRet="{ item }">
                    <v-checkbox v-model="item.hasRet" color="primary" disabled></v-checkbox>
                  </template>
                  <template v-slot:item.isAdditionalCharge="{ item }">
                    <v-checkbox v-model="item.isAdditionalCharge" color="primary"></v-checkbox>
                  </template>
                  <template v-slot:item.action="{ item, index }">
                    <v-btn :disabled="provedor.feesConfirmation" color="error" icon small
                           @click="removeCost(item, provedor, index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn v-if="item.isLast" :disabled="provedor.feesConfirmation" color="primary" icon small
                           @click="addCost(detailIndex, provedor)">
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
                <div class="flex d-flex justify-end">
                  <v-btn v-if="provedor.fees.length === 0" :disabled="provedor.feesConfirmation" color="primary" icon
                         small
                         @click="addCost(detailIndex, provedor)">
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </div>
              </v-container>

              <v-divider class="mt-4"></v-divider>

              <v-container>
                <h4 class="mt-4 ml-2">Solicitudes de pago</h4>

                <v-data-table :headers="headersSolicitudes" :hide-default-footer="false" :items="itemsSolicitudes"
                              :items-per-page="10" class="ml-4" dense @current-items="setFirstLast">
                  <!--                <template #item.data-table-expand="{ item, expand, isExpanded }">
                    <td v-if="item.fat < 10" class="text-start">
                      <v-btn icon
                             @click="expand(!isExpanded)"
                             class="v-data-table__expand-icon"
                             :class="{'v-data-table__expand-icon&#45;&#45;active' : isExpanded}">
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </td>
                  </template>-->
                  <template v-slot:header.data-table-select></template>
                  <template v-slot:item.dueDate="{ item }">
                    {{ item.dueDate | luxon }}
                  </template>
                  <template v-slot:item.invoiceNumbers="{ item }">
                    {{ item.invoiceNumbers.join(', ') }}
                  </template>
                  <template v-slot:item.detalle="{ item }">
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-btn class="ml-2" color="primary" fab icon large @click="getSolicitudDetail(item.id)" v-on="on"
                               @click.native.stop>
                          <v-icon> mdi-eye-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Ver detalle</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.Provision="{ item }">
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-btn class="ml-2" color="primary" fab icon large @click="getCost(item)" v-on="on"
                               @click.native.stop>
                          <v-icon> mdi-printer</v-icon>
                        </v-btn>
                      </template>
                      <span>Provision</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.total="{ item }">
                    {{ item.totalAmount - item.totalAdvance }}
                  </template>
                </v-data-table>
                <br>

              </v-container>


              <!--            <v-divider class="mt-4"></v-divider>
                          <v-col cols="12">
                            <file-drag
                              v-model="filesDrag"
                              @change="ConvertFile2()"
                            ></file-drag>
                          </v-col>-->
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn v-if="!prices.feesConfirmation" class="mt-6 mb-4 mr-6" color="primary" small @click="addSupplier">
          Añadir Provedor
          <v-icon dark right>
            mdi-plus-box
          </v-icon>
        </v-btn>
      </v-row>
       </v-col>
    <v-col v-if="!esVendedor" cols="4">
      <v-form ref="formSummarie" v-model="valid" lazy-validation>
        <v-container>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header color="orange">
                <h3 class="white--text">
                  <v-icon class="mb-1 mr-1" color="white">mdi-arrow-right-bold-circle
                  </v-icon>
                  {{ summarie.status }}
                </h3>
                <v-row class="justify-end mr-2">
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn class="mt-0 ml-4" color="white" icon large @click="cleanSummarie()" v-on="on"
                             @click.native.stop>
                        <v-icon> mdi-eraser</v-icon>
                      </v-btn>
                    </template>
                    <span>Limpiar solicitud</span>
                  </v-tooltip>
                </v-row>
              </v-expansion-panel-header>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
        <v-card-text class="px-5">
          <v-row>
            <v-col cols="12">
              <v-checkbox dense hide-details v-model="notification" label="Notificar"/>
              <v-checkbox dense hide-details v-model="summarie.Advance" label="Anticipo"/>
              <v-checkbox dense hide-details @change="setPaymentDate()" v-model="credit" label="Credito"/>
              <v-checkbox dense hide-details v-model="isFacturacion" label="Terceros"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select v-model="currencieSelected" :items="currencies2" item-text="code"
                        item-value="code" label="Tipo de moneda" @change="filterCurrency"
                        dense
              ></v-select>
              <v-select v-model="summarie.paymentType" :items="paymentTypes" item-text="name"
                        item-value="value"
                        label="Tipo de pago"
                        dense
              ></v-select>
              <v-text-field
                  :disabled="credit"
                  :rules="rulesDate.required"
                  v-model="summarie.DueDate"
                  label="Fecha de pago"
                  type="date"
                  clearable
                  dense
                  @click="validateFields"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-autocomplete v-model="summarie.freightForwarderId" :items="forwarders" class="pt-4"
                              dense item-text="companyName" item-value="id"
                              label="Embarcador (agente en origen)"
                              v-if="row === 1"
              ></v-autocomplete>
              <v-spacer class="mt-4"></v-spacer>
              <v-autocomplete v-model="summarie.supplierId" :items="itemsSuppliers" dense
                              v-if="row === 2"
                              item-text="companyName" item-value="id" label="Proveedor"></v-autocomplete>
              <v-text-field v-if="!summarie.Advance || !isFacturacion" v-model="summarie.invoiceNumber" dense
                            label="Número de factura"></v-text-field>

              <v-text-field v-model="summarie.invoiceIssuedAt" clearable
                            label="Fecha de factura"
                            type="date"
                            dense
                            v-if="!summarie.Advance || !isFacturacion"
              >
              </v-text-field>
              <v-spacer class="mb-4"></v-spacer>
            </v-col>
          </v-row>
          <v-row v-if="eEmbarque">
            <v-col class="mt-4" cols="4">
              <v-select class="mr-4" dense label="Evento de embarque"></v-select>
            </v-col>
            <v-col class="mt-4" cols="4">
              <v-select class="mr-4" dense label="Antes o despues"></v-select>
            </v-col>
            <template>
              <v-text-field class="mt-5 mr-3" dense label="Dias"></v-text-field>
            </template>
          </v-row>
          <v-row no-gutters class="py-6">
            <span class="text-subtitle-1 font-weight-bold">Solicitud de pago</span>
            <v-col cols="12" class="px-0">
              <template>
                <v-data-table
                    v-if="(this.shipment.transportationId === 1 || this.shipment.transportationId === 3) && shipment.transportationCategoryId !== 9"
                    :headers="headersSimple" :item-class="bgColor" :items="summarie.Fees" class="elevation-0"
                    hide-default-footer>
                  <template v-slot:item.concepto="{ item }">
                    <v-combobox
                        v-model="item.purchaseConcept"
                        dense
                        label="Editando nombre"
                        single-line
                        :items="globalConcepts"
                        :disabled="item.disable"
                        :loading="item.searchConceptLoading"
                        @update:search-input="updateConceptSearch($event, item)"
                        @change="onChangePurchaseFeeConcept($event, item)"
                        :error-messages="(item.purchaseConceptId === null || item.purchaseConceptId === 0) ? 'Favor seleccionar un concepto de compra' : ''"
                        item-value="id"
                        item-text="name"
                        no-filter
                        clearable
                        return-object
                    >
                    </v-combobox>
                  </template>
                  <template v-slot:item.sendNC="{ item, index }">
                    <v-checkbox
                        v-model="item.NC" @click="send2NC(index)"></v-checkbox>
                  </template>
                  <template v-slot:item.action="{ item, index }">
                    <v-btn v-if="summarie.Fees.length > 0" color="error" icon small
                           @click="delteSummarieFee(item, index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <template slot="body.append">
                    <tr>
                      <td>Subtotal</td>
                      <td colspan="4" align="left" class="mr-2">{{ totals.subTotal | toCurrency }}</td>
                    </tr>
                    <tr>
                      <td>IVA</td>
                      <td colspan="4" align="left" class="mr-2">{{ totals.iva | toCurrency }}</td>
                    </tr>
                    <tr>
                      <td>Ret. IVA</td>
                      <td colspan="4" align="left" class="mr-2">{{ totals.retencionIVA | toCurrency }}</td>
                    </tr>
                    <tr>
                      <td>Ret. ISR</td>
                      <td colspan="4" align="left" class="mr-2">{{ totals.retencionISR | toCurrency }}</td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td colspan="4" align="left" class="mr-2">{{ totals.total | toCurrency }}</td>
                    </tr>
                  </template>
                </v-data-table>
                <v-data-table v-else :headers="headersSummarieRatable" :item-class="bgColor" :items="summarie.Fees"
                              class="elevation-0" hide-default-footer>
                  <template v-slot:item.concepto="{ item }">
                    <v-combobox
                        v-model="item.purchaseConcept"
                        dense
                        label="Editando nombre"
                        single-line
                        :items="globalConcepts"
                        :disabled="item.disable"
                        :loading="item.searchConceptLoading"
                        @update:search-input="updateConceptSearch($event, item)"
                        @change="onChangePurchaseFeeConcept($event, item)"
                        item-value="id"
                        item-text="name"
                        no-filter
                        clearable
                        return-object
                        :error-messages="(item.purchaseConceptId === null || item.purchaseConceptId === 0) ? 'Favor seleccionar un concepto de compra' : ''"
                    >
                    </v-combobox>
                  </template>
                  <template v-slot:item.sendNC="{ item, index }">
                    <v-checkbox v-model="item.NC" @click="send2NC(index)"></v-checkbox>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-btn v-if="summarie.Fees.length > 0" color="error" icon small @click="delteSummarieFee(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <template slot="body.append">
                    <tr>
                      <td>Subtotal</td>
                      <td colspan="4" align="left" class="mr-2">{{ totals.subTotal | toCurrency }}</td>
                    </tr>
                    <tr>
                      <td>IVA</td>
                      <td colspan="4" align="left" class="mr-2">{{ totals.iva | toCurrency }}</td>
                    </tr>
                    <tr>
                      <td>Ret. IVA</td>
                      <td colspan="4" align="left" class="mr-2">{{ totals.retencionIVA | toCurrency }}</td>
                    </tr>
                    <tr>
                      <td>Ret. ISR</td>
                      <td colspan="4" align="left" class="mr-2">{{ totals.retencionISR | toCurrency }}</td>
                    </tr>
                    <tr class="font-weight-bold">
                      <td>Total</td>
                      <td colspan="4" align="left" class="mr-2">{{ totals.total | toCurrency }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </template>
            </v-col>
            <v-col>
              <v-textarea v-model="summarie.notes" label="Comentarios" outline></v-textarea>
              <file-drag v-model="fileDrag4" :rules="filRule" required @change="ConvertFile4()"></file-drag>
            </v-col>
          </v-row>
          <span class="mt-2 text-subtitle-2 font-weight-bold">Notas de Credito</span>
          <v-tabs
              v-model="tabCreditNote"
              v-if="summarie.id !== 0"
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab v-for="(creditNote, index) in creditNotes" :key="`nc-tab-${index}`">
              <span class="my-auto text-caption font-weight-bold">NC {{ creditNote.id || 0 }}</span>
              <v-btn class="my-auto mx-2" icon small @click="onRemoveCreditNote(creditNote, index)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-tab>

            <v-btn
                text
                @click="addCreditNote()"
                icon
                class="my-auto"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-tabs>

          <v-tabs-items v-model="tabCreditNote">
            <v-tab-item v-for="creditNote in creditNotes" :key="`nc-tab-item-${creditNote.id || 0}`">
              <v-card class="py-6 px-2 mx-0" color="grey lighten-5">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-text-field v-model="creditNote.invoiceNumber" label="Número Nota de Credito"
                    ></v-text-field>
                    <v-text-field v-model="creditNote.issuedAt" label="Fecha Nota de Credito"
                                  type="date"
                                  dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-data-table
                        :headers="headersNC"
                        :items="creditNote.fees"
                        class="elevation-0 grey lighten-5"
                        hide-default-footer
                    >
                      <template v-slot:item.concepto="{ item }">
                        <v-combobox
                            v-model="item.concept"
                            class="mt-3"
                            dense
                            item-text="name"
                            item-value="id"
                            label="Concepto general"
                            return-object
                            no-filter
                            @update:search-input="updateConceptSearch($event, item)"
                            @change="creditNoteTotals(creditNote)"
                            single-line
                            :items="globalConcepts"
                            :loading="item.searchConceptLoading"
                            clearable
                        >
                        </v-combobox>
                      </template>
                      <template v-slot:item.total="{ item }">
                        <v-text-field v-model.number="item.total" @input="creditNoteTotals(creditNote)"></v-text-field>
                      </template>
                      <template v-slot:item.action="{ item, index }">
                        <v-btn v-if="creditNote.fees.length > 0" color="error" icon small
                               @click="deleteFeeNC(creditNote, index)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:body.append>
                        <tr>
                          <th colspan="5">
                            <v-btn icon color="info" small @click="addCreditNoteFee(creditNote)">
                              <v-icon>mdi-plus-circle</v-icon>
                            </v-btn>
                          </th>
                        </tr>
                        <tr>
                          <td class="text-body-1">Subtotal</td>
                          <td class="text-body-1">{{ creditNote.totalsNC.subtotal | toCurrency }}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td class="text-body-1">IVA</td>
                          <td class="text-body-1">{{ creditNote.totalsNC.iva | toCurrency }}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td class="text-body-1">Ret. IVA</td>
                          <td class="text-body-1">{{ creditNote.totalsNC.retIva | toCurrency }}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td class="text-body-1">Ret. ISR</td>
                          <td class="text-body-1">{{ creditNote.totalsNC.retIsr | toCurrency }}</td>
                          <td></td>
                        </tr>
                        <tr class="font-weight-bold">
                          <td class="text-body-1">Total</td>
                          <td class="text-body-1 mr-2" colspan="4" align="left">{{ creditNote.totalsNC.total | toCurrency }}</td>
                          <td></td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12" style="min-height: 100px">
                    <file-drag v-model="creditNote.file" class="mb-1"
                               :rules="filRule"
                               required
                               @change="mapFileCreditNote(creditNote)"></file-drag>
                  </v-col>
                  <v-col cols="12" class="text-left my-2">
                    <div v-if="creditNoteValidations.length > 0" class="red lighten-5">
                      <v-row v-for="validation in creditNoteValidations" :key="`nc-validation-${validation}`">
                        <v-col cols="12" class="pl-4">
                          <v-icon color="red">mdi-alert-circle</v-icon>
                          <span class="red--text">{{ validation }}</span>
                        </v-col>
                      </v-row>
                    </div>
                    <v-btn class="mt-4" color="primary" @click="createCreditNote(creditNote)"
                           v-if="creditNote.id === 0">Crear Nota Credito
                    </v-btn>
                    <v-btn class="mt-4" color="primary" @click="updateCreditNote(creditNote)" v-else>Actualizar Nota Credito</v-btn>
                  </v-col>
                </v-row>
              </v-card>

            </v-tab-item>
          </v-tabs-items>

          <v-row v-if="isFacturacion">
            <v-col cols="12">
              <h2 class="font-weight-regular mt-2 mb-2">Terceros</h2>
              <v-expansion-panels multiple>
                <v-expansion-panel v-for="(supplier, indexS) in suppliersSummarie" :key="indexS">
                  <v-expansion-panel-header expand-icon="mdi-menu-down" @click="indexSupplier = indexS">
                    <div v-if="indexS === 0">
                      Proveedor {{ indexS + 1 }} MAIN
                    </div>
                    <div v-else>
                      Proveedor {{ indexS + 1 }}
                    </div>
                    <div class="d-flex justify-end">
                      <v-btn v-if="suppliersSummarie.length > 1" color="error" icon small
                             @click="deleteSupplierSummarie(detailIndex)"
                             @click.native.stop>
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                      <v-btn v-if="supplier.isLast" color="primary" icon small @click="addSupplierSummarie"
                             @click.native.stop>
                        <v-icon>mdi-plus-circle</v-icon>
                      </v-btn>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="mr-1 ml-1">
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete v-model="supplier.supplierId" :items="itemsSuppliers" dense
                                        item-text="companyName"
                                        item-value="id" label="Provedores"></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-menu v-model="supplier.menu" :close-on-content-click="false" :nudge-right="40"
                                min-width="auto" offset-y transition="scale-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="supplier.invoiceDate" dense label="Fecha de factura"
                                          prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                          </template>
                          <v-date-picker v-model="supplier.invoiceDate" :max="getCurrentDate()" color="primary"
                                         @input="supplier.menu = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="supplier.invoiceNumber" dense label="Numero de factura"></v-text-field>
                      </v-col>

                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn class="mt-6 mb-4 mr-6" color="primary" small @click="copyFees(indexS)">
                          Copiar fees de solicitud
                          <v-icon dark right>
                            mdi-content-copy
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-data-table v-if="toShow" :headers="headerMultiple" :items="supplier.fees"
                                      class="elevation-0 mb-4"
                                      hide-default-footer @current-items="setFirstLast">
                          <template v-slot:item.name="{ item, index }">
                            <v-autocomplete v-model="item.concept" :items="multipleConceptItems"
                                            :search-input.sync="item.searchConcetp"
                                            class="mt-3" dense
                                            item-text="name" item-value="id" label="Concepto general"
                                            return-object
                                            @click="setIndexes(indexS, index)">
                            </v-autocomplete>
                          </template>
                          <template v-slot:item.quantity="{ item }">
                            <v-text-field v-model="item.quantity" autofocus label="Editando Cantidad" single-line
                                          type="number"></v-text-field>
                          </template>
                          <template v-slot:item.basePrice="{ item }">
                            <v-text-field v-model.number="item.basePrice" autofocus label="Editando Compra"
                                          single-line type="number"></v-text-field>
                          </template>
                          <template v-slot:item.action="{ item, index }">
                            <div class="d-flex justify-end">
                              <v-btn v-if="!item.isFirst" color="error" icon small
                                     @click="deleteFeeSupplierSummarie(indexS, index)" @click.native.stop>
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                              <v-btn v-if="item.isLast" color="primary" icon small
                                     @click="addFeeSupplierSummarie(indexS)"
                                     @click.native.stop>
                                <v-icon>mdi-plus-circle</v-icon>
                              </v-btn>
                            </div>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                    <file-drag v-model="supplier.invoiceDocAux" class="mb-1" required
                               @change="ConvertFileSummarie(supplier, indexS)"></file-drag>
                    <!--                    <file-drag
                        class="mb-1"
                        v-model="supplier.invoiceDocAux"

                        required
                    ></file-drag>-->
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row class="ml-1 mr-1 mb-2 mt-3">
            <v-btn v-if="summarie.Fees.length >= 1 &&
              summarie.status === 'No hay solicitud'
              "
                   :disabled="tryCreditNote || (date === null || isConcept) || ((row === 1 && summarie.freightForwarderId === null) || (row === 2 && summarie.supplierId === null))"
                   block class="mt-3" color="primary"
                   @click="paymentRequest = true">
              Solicitar Pago
            </v-btn>
            <v-btn v-if="summarie.status !== 'No hay solicitud'"
                   :disabled="tryCreditNote || !(isConcept) || ((row === 1 && summarie.freightForwarderId === null) || (row === 2 && summarie.supplierId === null))"
                   block color="primary"
                   @click="paymentUpdate = true">
              Actualizar
            </v-btn>
            <v-btn v-if="summarie.status === 'Solicitados'"
                   block class="mt-3" color="error" @click="cancelSolicitud">Cancelar
            </v-btn>
            <!-- <v-btn v-if="summarie.status === 'Solicitados' || summarie.status === 'Pagados'" :disabled="tryCreditNote"
                   block
                   class="mt-3" color="blue" dark @click="habrirModal">
              Importar Factura
            </v-btn> -->
            <v-btn v-if="summarie.Advance && !summarie.isFirt" :disabled="tryCreditNote" block class="mt-3" color="blue"
                   dark @click="restPayment = true">
              Pagar restante
            </v-btn>
            <!-- <v-dialog v-model="dialogImport" width="850">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="summarie.status === 'pagado'" block class="mt-3" color="blue" dark v-bind="attrs"
                       v-on="on">
                  Importar Factura
                </v-btn>
              </template>

              <v-card width="850">
                <v-toolbar app class="primary white--text" dark>
                  <h2 class="font-weight-regular">
                    <v-icon size="25">mdi-file-edit</v-icon>
                    Importar Factura
                  </h2>

                  <v-spacer></v-spacer>
                  <v-btn dark icon @click="dialogImport = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-row class="mt-4 ml-11">
                  <v-col cols="11">
                    <v-text-field v-model="importarData.invoiceNumber" dense label="Numero de factura"></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="ml-11">
                  <v-col cols="11">
                    <v-menu ref="menu111" v-model="menu111" :close-on-content-click="false"
                            :return-value.sync="importarData.invoiceIssuedAt" :rules="filRule" min-width="auto"
                            offset-y required transition="scale-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="importarData.invoiceIssuedAt" append-icon="mdi-calendar"
                                      dense label="Fecha de factura" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="importarData.invoiceIssuedAt" :max="new Date().toISOString()"
                                     color="primary"
                                     no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="menu111 = false">
                          Cancel
                        </v-btn>
                        <v-btn color="primary" text @click="$refs.menu111.save(importarData.invoiceIssuedAt)">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="mt-4 ml-11">
                  <v-col cols="11">
                    <v-text-field v-model="importarData.totalAmout" dense label="Total de factura"></v-text-field>
                  </v-col>
                </v-row>
                <v-card-text>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-col cols="12">
                      <file-drag
                          v-if="importarData.totalAmout && importarData.invoiceNumber && importarData.invoiceIssuedAt"
                          v-model="fileDragImportar" required @change="converFileImportar()"></file-drag>
                    </v-col>
                  </v-form>
                </v-card-text>
                <v-card-actions elevation="0">
                  <v-spacer></v-spacer>
                  <v-btn color="error" @click="dialogImport = false">
                    Cancelar
                  </v-btn>
                  <v-btn
                      :disabled="importarData.invoiceIssuedAt === null || importarData.invoiceNumber === null || importarData.totalAmout === 0 || fileDragImportar.length === 0"
                      color="primary"
                      @click="importarFactura(aux)">
                    Aceptar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog> -->
          </v-row>
        </v-card-actions>
      </v-form>
    </v-col>
    <v-dialog v-model="dialogDelit" width="500px">
      <v-card>
        <v-toolbar app class="red white--text" dark>
          <h2 class="font-weight-regular">
            <v-icon size="25">mdi-file-edit</v-icon>
            BORRAR PRECIO COMPLETO
          </h2>

          <v-spacer></v-spacer>
          <v-btn dark icon @click="dialogDelit = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="mt-7 mb-1" style="justify-content: center">
            Esta seguro que desea borrar el precio completo
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn block color="red" style="color: white" @click="deleteSupplier(Supplyer2Delete)">Close Dialog</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEdit" max-width="800">
      <v-card width="800">
        <v-toolbar app class="primary white--text" dark>
          <h2 class="font-weight-regular">
            <v-icon size="25">mdi-file-edit</v-icon>
            Editar compra
          </h2>

          <v-spacer></v-spacer>
          <v-btn dark icon @click="dialogEdit = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-textarea v-model="saveComment" :rules="filRule" class="mt-4" label="Comentarios" outlined
                        :rows="2"
                        required></v-textarea>
            <v-col cols="12">
              <file-drag v-model="filesDrag2" :rules="filRule" required @change="ConvertFile()"></file-drag>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions elevation="0">
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dialogEdit = false">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="editFee(aux)">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEdit2" max-width="800">
      <v-card width="800">
        <v-toolbar app class="primary white--text" dark>
          <h2 class="font-weight-regular">
            <v-icon size="25">mdi-file-edit</v-icon>
            Salvar compra
          </h2>

          <v-spacer></v-spacer>
          <v-btn dark icon @click="dialogEdit2 = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-textarea v-model="saveComment" :rules="filRule" class="mt-4" label="Comentarios" outlined
                        required></v-textarea>
            <v-col cols="12">
              <file-drag v-model="filesDrag3" :rules="filRule" required @change="ConvertFile3()"></file-drag>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions elevation="0">
          <v-spacer></v-spacer>
          <v-btn color="error" @click="cancelConfirm()">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="confirmPrice2()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEdit3" max-width="800">
      <v-card width="800">
        <v-toolbar app class="primary white--text" dark>
          <h2 class="font-weight-regular">
            <v-icon size="25">mdi-book-cancel</v-icon>
            Cancelar solicitud
          </h2>

          <v-spacer></v-spacer>
          <v-btn dark icon @click="dialogEdit3 = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-textarea v-model="commentCancel" :rules="filRule" class="mt-4" label="Comentario" outlined
                        required></v-textarea>
            <v-col cols="12">
              <v-select v-model="cancelReason" :items="itemsCancelReasons" item-text="description"
                        item-value="id"></v-select>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions elevation="0">
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dialogEdit3 = false">
            Cancelar
          </v-btn>
          <v-btn :disabled="cancelReason === 0" color="primary" @click="cancelSolicitud2()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="paymentRequest" max-width="500">
      <v-card>
        <v-toolbar app class="error white--text" dark>
          <h2 class="font-weight-regular">
            <v-icon class="mr-2" size="25">mdi-send</v-icon>
            CONFIRMAR PAGO
          </h2>

          <v-spacer></v-spacer>
          <v-btn dark icon @click="paymentRequest = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="mt-4">
          LA SOLICITUD SE ESTARA <b>PAGANDO</b> LO ANTES POSIBLE "FAVOR DE REVISAR A DETALLE"
        </v-card-text>

        <v-card-actions elevation="0">
          <v-spacer></v-spacer>
          <v-btn color="error" @click="paymentRequest = false">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="sendSummary()">
            Solicitar Pago
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="paymentUpdate" max-width="500">
      <v-card>
        <v-toolbar app class="error white--text" dark>
          <h2 class="font-weight-regular">
            <v-icon class="mr-2" size="25">mdi-send</v-icon>
            ACTUALIZAR PAGO
          </h2>

          <v-spacer></v-spacer>
          <v-btn dark icon @click="paymentUpdate = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="mt-4">
          LA SOLICITUD SE ESTARA <b>ACTUALIZANDO</b> "FAVOR DE REVISAR A DETALLE"
        </v-card-text>

        <v-card-actions elevation="0">
          <v-spacer></v-spacer>
          <v-btn color="error" @click="paymentUpdate = false">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="updateSummarie()">
            Actualizar Pago
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="creditNoteCreat" max-width="500">
      <v-card>
        <v-toolbar app class="error white--text" dark>
          <h2 class="font-weight-regular">
            <v-icon class="mr-2" size="25">mdi-send</v-icon>
            CREAR NOTA DE CREDITO
          </h2>

          <v-spacer></v-spacer>
          <v-btn dark icon @click="creditNoteCreat = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="mt-4">
          LA SOLICITUD DE NOTA DE CREDITO SE ESTARA <b>ABONANDO</b> AL PROVEDOR "FAVOR DE REVISAR A DETALLE"
        </v-card-text>

        <v-card-actions elevation="0">
          <v-spacer></v-spacer>
          <v-btn color="error" @click="creditNoteCreat = false">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="sendSummary(true)">
            Crear Nota de credito
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="restPayment" max-width="500">
      <v-card>
        <v-toolbar app class="error white--text" dark>
          <h2 class="font-weight-regular">
            <v-icon class="mr-2" size="25">mdi-send</v-icon>
            CANCELAR PAGO
          </h2>

          <v-spacer></v-spacer>
          <v-btn dark icon @click="restPayment = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="mt-4">
          LA SOLICITUD DE PAGO SE ESTARA <b>PAGANDO</b> "FAVOR DE REVISAR A DETALLE"
        </v-card-text>

        <v-card-actions elevation="0">
          <v-spacer></v-spacer>
          <v-btn color="error" @click="restPayment = false">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="PaymentAdvancedSolo">
            Pagar Restante
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import FileDrag from "@/components/Global/FileDrag/FileDrag";
// import OrderSearchBar from "@/components/Global/OrderSearchBar/OrderSearchBar";
// import SenStatusBar from "@/views/Pages/Operation/components/SenStatusBar";
// import SenContainers from "@/views/Pages/Operation/components/SenContainers";
// import SenRouteTimeline from "@/views/Pages/Operation/components/SenRouteTimeline";
// import Route from "@/views/Pages/Operation/models/route";
// import RouteOrigin from "@/views/Pages/Operation/models/route_origin";
import debouncePromise from "debounce-promise";
import {DateTime} from 'luxon'
import {getS3} from "@/composables/filesS3";
import {debounce} from "debounce";

var fileDownload = require('js-file-download')
export default {
  name: "CompraBL",
  components: {
    FileDrag,
  },
  props: {
    esVendedor: {
      type: Boolean,
      default: false,
    },
    shipment: {},
  },
  data: (vm) => ({
    loadingForwarders: false,
    creditNoteValidations: [],
    currencyIdSelected: 0,
    creditNotes: [],
    issuedAt: null,
    toShow: true,
    storedFees: [],
    editFees: false,
    validTransportation: false,
    paymentForm: false,
    isLoading: false,
    docsURL: [],
    rulesDate: {
      required: [value => !!value || ""]
    },
    tryCreditNote: false,
    isCreditNote: false,
    rules: {
      counter: value => value > 0 || 'Tiene que ser mayor a 0'
    },
    fileDragImportar: [],
    importarData: {
      invoiceNumber: null,
      invoiceIssuedAt: null,
      totalAmout: 0,
    },
    tabCreditNote: 0,
    facturaImportar: [],
    headersNC: [
      {
        text: "Concepto",
        value: "concepto",
      },
      {
        text: "Compra",
        value: "total",
      },
      {
        text: "",
        value: "action",
      },
    ],
    headersNotas: [
      {
        text: 'Id Nota de credito',
        value: 'id'
      },
      {
        text: 'Numero de factura',
        value: 'invoiceNumber'
      },
      {
        text: 'Provedor',
        value: 'supplierId'
      },
      {
        text: 'Total',
        value: 'total'
      },
      {
        text: 'Ver detalle',
        value: 'detalle'
      }
    ],
    rowSummarie: 1,
    headerMultiple: [
      {
        text: "Concepto General",
        value: "name",
      },
      {
        text: "Cantidad",
        value: "quantity",
      },
      {
        text: "Compra",
        value: "basePrice",
      },
      {
        text: "",
        value: "action",
      },
    ],
    indexSupplier: null,
    indexFee: null,
    multipleConceptItems: [],
    defaultFeeSS: {
      name: '',
      conceptId: null,
      concept: null,
      quantity: 1,
      basePrice: 1,
      iva: 0,
      ivaAcreditable: '0',
      retIva: 0,
      retIsr: 0,
      ledgerAccount: 0,
      searchConcept: null,
    },
    suppliersSummarie: [
      {
        fees: [{
          concept: null,
          searchConcept: null,
          conceptId: null,
          name: '',
          quantity: 1,
          basePrice: 1,
          iva: 0,
          ivaAcreditable: "0",
          retIva: 0,
          retIsr: 0,
          ledgerAccount: 0
        }],
        rowSummarie: 1,
        id: 0,
        isFirst: true,
        isLast: true,
        supplierId: 0,
        forwarderId: 0,
        invoiceNumber: null,
        totalAmount: 0,
        invoiceDate: null,
        invoiceDocAux: [],
        invoiceFileName: null,
        invoiceFileExtension: null,
        menu: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      }
    ],
    paymentRequest: false,
    creditNoteCreat: false,
    restPayment: false,
    paymentUpdate: false,
    isFacturacion: false,
    notification: true,
    Supplyer2Delete: null,
    isSolicitud: false,
    headersSummarieRatable: [
      {
        text: "Concepto General",
        value: "concepto",
      },
      {
        text: "Ratable Weight",
        value: "ratableWeight",
      },
      {
        text: "Venta total",
        value: "total",
      },
      {
        text: 'NC',
        value: "sendNC"
      },
      {
        text: "",
        value: "action",
      },
    ],
    guardoIndex: null,
    guardoIndex2: null,
    oldSearch2: null,
    oldSearch: null,
    select: {state: 'Florida', abbr: 'FL'},
    items5: [
      {state: 'Florida', abbr: 'FL'},
      {state: 'Georgia', abbr: 'GA'},
      {state: 'Nebraska', abbr: 'NE'},
      {state: 'California', abbr: 'CA'},
      {state: 'New York', abbr: 'NY'},
    ],
    indexConcept: null,
    indexConcept2: null,
    searchConcept: null,
    menuInfo: false,
    menuInfo2: false,
    invoiceIssuedAtDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    invoiceNumberFactura: null,
    totalAmount: null,
    guardadoRestante: 0,
    restante: 0,
    row: 2,
    Shp: [],
    dialogImport: false,
    provedor: false,
    agente: false,
    payTo: "",
    freightForwarderId: null,
    total: 0,
    supplierId: null,
    isConcept: true,
    isConcept2: true,
    radioAgente: false,
    radioProvedor: false,
    panel: [],
    itemsFiltered: [],
    currencieSelected: "",
    currencies2: [
      {
        code: "USD",
        id: 1,
        name: "US dollar",
        status: true,
        symbol: "$",
      },
      {
        code: "EUR",
        id: 34,
        name: "Euro",
        status: true,
        symbol: "€",
      },
      {
        code: "MXN",
        id: 27,
        name: "Mexican peso",
        status: true,
        symbol: "$",
      },
    ],
    provedorG: null,
    indexG: null,
    itemsSolicitudes: [],
    aux: [],
    filRule: [(v) => (v && v.length > 0) || "Porfavor llene el campo"],
    selectRule: [(v) => !!v || "Porfavot selecciona una opcion"],
    fileDrag4: [],
    itemsConcept: [],
    cancelReason: 0,
    itemsCancelReasons: [],
    commentCancel: "",
    dialogEdit3: false,
    paymentId: 0,
    isDetail: false,
    ddate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    itemsSuppliers: [],
    saveComment: "",
    docConfirm: [],
    infoIndex: {},
    docLleno: false,
    valid: true,
    edit: {
      comments: "",
      documents: [],
    },
    payments: [],
    confirmPrices: [],
    dialogEdit: false,
    dialogEdit2: false,
    dialogDelit: false,
    dialog: false,
    indexExpansion: null,
    final: false,
    currentTab: 1,
    summarySelected: [
      {
        selectedCargo: [],
      },
    ],
    prices: [],
    paymentTypes: [
      {
        name: 'Transferencia',
        value: 'transfer'
      },
      {
        name: 'Efectivo',
        value: 'cash'
      },
      {
        name: 'Cheque',
        value: 'cheque'
      }
    ],
    summarie: {
      issuedAt: null,
      paymentRequestId: 0,
      itemsNC: [],
      isNumberOrDate: false,
      isFirt: true,
      totalAmount: 0,
      Id: 0,
      invoiceNumber: null,
      aPagar: 0,
      invoiceIssuedAt: null,
      Urgent: false,
      Advance: false,
      prePaid: false,
      DueDate: null,
      paymentType: 'transfer',
      CreditDays: false,
      selectedCargo: [],
      purchaseId: 0,
      ConceptId: 1,
      TotalAdvance: 0,
      payTo: "",
      freightForwarderId: null,
      total: 0,
      supplierId: null,
      Fees: [],
      documents: [],
      status: "No hay solicitud",
      suppliers: null,
    },
    summaries: [
      {
        StatusPayment: "Solicitado",
        Urgent: false,
        Advance: false,
        paymentType: false,
        CreditDays: false,
        DueDate: "",
        selectedCargo: [],
        ConceptId: "",
        TotalAdvance: "",
        payTo: "",
        freightForwarderId: null,
        total: 0,
        supplierId: null,
        Fees: [],
      },
    ],
    allRows: false,
    singleRow: false,
    switch1: true,
    eEmbarque: false,
    sFecha: true,
    editedIndex: -1,
    headersSolicitudes: [
      {
        text: "Id de solicitud",
        value: "id",
        align: "center",
      },
      {
        text: "Estatus de solicitud",
        value: "paymentStatus.description",
        align: "center",
      },
      {
        text: "Fecha",
        value: "dueDate",
        align: "center",
      },
      {
        text: "Num Factura",
        value: "invoiceNumbers",
        align: "center",
      },
      {
        text: "Proveedor",
        value: "supplierName",
        align: "center",
      },
      /* {
         text: "Anticipo",
         value: "totalAdvance",
         align: "center",
       },
       {
         text: "Restante",
         value: "totalAmount",
         align: "center",
       },*/
      {
        text: "Total Pendiente",
        align: "center",
        value: "total",
      },
      {
        text: "Total NC",
        value: "creditNote.total",
        align: "center"
      },
      {
        text: "Provision",
        value: "Provision",
        align: "center",
      },
      {
        text: "Ver detalle",
        value: "detalle",
        align: "center",
      },
    ],
    headerss: [
      {
        text: "Usuario",
        align: "start",
        sortable: false,
        value: "name",
      },
      {text: "Evento", value: "calories"},
      {text: "Fecha", value: "fat"},
      {text: "Evidencia", value: "iron"},
    ],
    resume: true,
    length: 1,
    details: [],
    Currencies: [],
    expanded: [],
    tab: null,
    desserts: [],
    e6: 1,
    masterBl: {
      shipper: null,
      consignee: null,
      shipping: null,
      BlType: null,
      BL: null,
    },
    headersParent: [
      {
        text: "Supplier",
        align: "left",
        value: "supplier.name",
      },
    ],

    headersChild: [
      {
        text: "Select",
        align: "left",
        value: "data-table-select",
        class: "checkbox",
        cellClass: "checkbox",
      },
      {
        text: "Cargo",
        align: "left",
        value: "name",
      },
      {
        text: "Currency",
        align: "left",
        value: "currency.name",
      },
      {
        text: "Quantity",
        align: "left",
        value: "quantity",
      },
      {
        text: "Price",
        align: "left",
        value: "price",
      },
      {
        text: "Profit",
        align: "left",
        value: "profit",
      },
      {
        text: "Total",
        align: "left",
        value: "total",
      },
      {
        text: "Status",
        align: "left",
        value: "status",
      },
      {
        text: "RET",
        align: "left",
        value: "hasRet",
      },
      {
        text: "IVA",
        align: "left",
        value: "hasVat",
      },
      {
        text: "cargo adicional",
        align: "left",
        value: "isAdditionalCharge",
      },
      {
        text: "accion",
        align: "left",
        value: "botones",
      },
    ],

    headersChildsales: [
      {
        text: "Select",
        align: "left",
        value: "data-table-select",
        class: "checkbox",
        cellClass: "checkbox",
      },
      {
        text: "Cargo",
        align: "left",
        value: "name",
      },
      {
        text: "Currency",
        align: "left",
        value: "currency.name",
      },
      {
        text: "Quantity",
        align: "left",
        value: "quantity",
      },
      {
        text: "Price",
        align: "left",
        value: "price",
      },
      {
        text: "Profit",
        align: "left",
        value: "profit",
      },
      {
        text: "Total",
        align: "left",
        value: "total",
      },
      {
        text: "Status",
        align: "left",
        value: "status",
      },
      {
        text: "RET",
        align: "left",
        value: "hasRet",
      },
      {
        text: "IVA",
        align: "left",
        value: "hasVat",
      },
      {
        text: "cargo adicional",
        align: "left",
        value: "isAdditionalCharge",
      },
      {
        text: "accion",
        align: "left",
        value: "botones",
      },
    ],

    shipping: {},
    items2: [
      {
        action: "mdi-ticket",
        items: [{title: "Documento"}, {title: "Documento"}],
        title: "Informacion sobre solicitud",
      },
      {
        action: "mdi-ticket",
        items: [{title: "PDF de venta"}, {title: "Documento"}],
        title: "Servicos",
      },
      {
        action: "mdi-ticket",
        items: [{title: "PDF de venta"}, {title: "Documento"}],
        title: "Venta",
      },
      {
        action: "mdi-school",
        items: [{title: "Documento de compra"}],
        title: "Compra",
      },
    ],

    items3: [
      {
        action: "mdi-ticket",
        items: [{title: "Documento"}, {title: "Documento"}],
        title: "Solcitud de pago",
      },
      {
        action: "mdi-ticket",
        items: [{title: "PDF de venta"}, {title: "Documento"}],
        title: "Servicos",
      },
    ],
    initiallyOpen: ["public"],
    files2: {
      html: "mdi-language-html5",
      js: "mdi-nodejs",
      json: "mdi-code-json",
      md: "mdi-language-markdown",
      pdf: "mdi-file-pdf",
      png: "mdi-file-image",
      txt: "mdi-file-document-outline",
      xls: "mdi-file-excel",
    },
    companies: [],
    BlType: [
      {
        id: 1,
        name: "Origin",
      },
      {
        id: 2,
        name: "Telex",
      },
      {
        id: 3,
        name: "Swb",
      },
    ],
    tree: [],
    forwarders: [],
    itemsF: [
      {
        name: "Factura.CMA",
      },
      {
        name: "Factura.Trans",
      },
    ],
    IdOrder: null,
    time: null,
    menu: "",
    menu111: "",
    menu11: "",
    menu2: false,
    menu5: false,
    modal2: false,

    opciones: ["H/BL", "M/BL"],

    date: new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    trip: {
      name: "",
      location: null,
      start: null,
      end: null,
    },
    // New
    events: [],
    input: null,
    nonce: 0,
    houseFiles: [],
    masterFiles: [],
    alerts: [
      {
        name: "Recolección",
        date: "2021/05/01",
        text: null,
      },
      {
        name: "Llego a POL",
        date: "2021/05/01",
        text: null,
      },
    ],
    defaultAlert: {
      name: null,
      date: null,
      text: null,
    },
    confimedCharges: false,
    chargesWorking: false,
    demoCargos: [
      {
        cargo: "Cargo",
        moneda: "",
        iva: "",
        ivaret: "",
        cantidad: 1,
        ratableWeight: 5,
        compra: 600,
        venta: 1000,
        profit: 400,
        selected: false,
      },
    ],
    defaultCosto: {
      cargo: "",
      moneda: "",
      iva: "",
      ivaret: "",
      cantidad: "",
      compra: "",
      venta: "",
      profit: "",
    },
    suppliers: [],
    shipmentIsValid: true,
    shipmentWorking: false,
    requiredRule: [(v) => !!v || "Este campo es obligatorio"],
    requiredSelectRule: [(v) => v.length > 0 || "Este campo es obligatorio"],
    items: [
      {
        action: "mdi-ticket",
        items: [{title: "List Item"}],
        title: "Proveedro",
      },
      {
        action: "mdi-ticket",
        items: [{title: "List Item"}],
        title: "Cliente",
      },
      {
        action: "mdi-ticket",
        items: [{title: "List Item"}],
        title: "Provedor de cliente",
      },
    ],

    itemsB: [
      {
        action: "mdi-ticket",
        items: [{title: "List Item"}],
        titleB: "Booking: 31931101221",
      },
    ],

    emailValid: [
      (v) => {
        if (!v || v.length < 1) return "Debes agregar al menos un correo";
        else if (!v || v.length > 0) {
          for (let i = 0; i < v.length; i++) {
            if (!/.+@.+\..+/.test(v[i])) {
              return "Un correo no es valido.";
            }
          }
        }
        return true;
      },
    ],
    searchEmails: null,
    shippingStatus: [
      {
        id: 1,
        name: "Provisional",
      },
      {
        id: 2,
        name: "En tránsito",
      },
      {
        id: 3,
        name: "Arribados",
      },
      {
        id: 4,
        name: "Detenido",
      },
      {
        id: 5,
        name: "Entregados",
      },
      {
        id: 6,
        name: "Cerrados",
      },
    ],
    ogStatus: [
      {
        id: 1,
        name: "Provisional",
      },
      {
        id: 2,
        name: "En tránsito",
      },
      {
        id: 3,
        name: "Arribados",
      },
      {
        id: 4,
        name: "Detenido",
      },
      {
        id: 5,
        name: "Entregados",
      },
      {
        id: 6,
        name: "Cerrados",
      },
    ],
    itemsSummary: [],
    shipmentToServer: {
      id: null,
      incotermId: null,
      mails: null,
      contactName: null,
      clientSupplierName: null,
      referenceNumber: null,
      quotationId: null,
      shippingStatusId: null,
      clientId: null,
    },

    inconterms: [],
    operators: [],
    // End New
    facturaDrawer: false,
    dialogReclamo: false,
    paymentDrawer: false,
    showSolicitud: false,
    filesDrag: [],
    filesDrag2: [],
    filesDrag3: [],
    selectedCargo: [{}],
    defaultCharge: {
      name: null,
      container: null,
      currency: {
        code: null,
        id: null,
        name: null,
        status: null,
        symbol: null,
      },
      purchaseDetailId: 0,
      currencyId: 0,
      hasRet: false,
      hasVat: false,
      isAdditionalCharge: false,
      PurchaseFeeId: 0,
      isFirst: null,
      isLast: null,
      price: 0,
      id: 0,
      quantity: 0,
      ratableWeight: 0,
      status: null,
      total: null,
      selected: false,
    },
    charges: [],
    headersSimple: [
      {
        text: "Concepto General",
        value: "concepto",
      },
      {
        text: "Cantidad",
        value: "quantity",
      },
      {
        text: "Compra",
        value: "total",
      },
      {
        text: 'NC',
        value: "sendNC"
      },
      {
        text: "",
        value: "action",
      },
    ],
    elSuperTotal: 0,
    elSuperTotal2: 0,
    headers: [
      {
        text: "Cargo",
        value: "name",
      },
      {
        text: "Ratable weight",
        value: "ratableWeight",
      },
      {
        text: "Cantidad",
        value: "quantity",
      },
      {
        text: "Compra",
        value: "total",
      },
      {
        text: "Compra total",
        value: "compraTotal"
      },
      {
        text: "Moneda",
        value: "currency.code",
      },
      {
        text: "IVA",
        value: "hasVat",
      },
      {
        text: "IVA - Ret",
        value: "hasRet",
      },
      {
        text: "cargo adicional",
        value: "isAdditionalCharge",
      },


      {
        text: "",
        value: "action",
      },
    ],
    editOrCreate: false,
    currencies: [],
    // Quitar
    files: [
      "File 01.pdf",
      "File 02.pdf",
      "File 03.pdf",
      "File 04.pdf",
      "File 05.pdf",
      "File 06.pdf",
      "File 07.pdf",
      "File 08.pdf",
      "File 09.pdf",
      "File 10.pdf",
    ],
    // End Quitar
    show: false,

    mini: !vm.$vuetify.breakpoint.xl,
    credit: null,
    purchaseFees: [],
    globalConcepts: [],
    defaultCreditNoteFee: {},
    isPurchaseValid: true
  }),
  watch: {
    'suppliersSummarie': {
      deep: true,
      handler() {
        if (this.indexSupplier !== null && this.indexFee !== null) {
          console.log('supplier: ', this.indexSupplier, 'indexfee: ', this.indexFee)
          console.log(this.suppliersSummarie[this.indexSupplier])
          this.searchConceptMultiple(this.suppliersSummarie[this.indexSupplier].fees[this.indexFee].searchConcetp);
        }
      },
    },
    // 'summarie.isNumberOrDate'
    'summarie.invoiceNumber'() {
      if (this.summarie.invoiceNumber !== null) {
        this.summarie.isNumberOrDate = true;
      }
    },
    'summarie.invoiceIssuedAt'() {
      if (this.summarie.invoiceIssuedAt !== null) {
        this.summarie.isNumberOrDate = true;
      }
      let todayDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10);
      if (this.summarie.invoiceIssuedAt > todayDate) {
        this.summarie.invoiceIssuedAt = null
        this.$toast.error('Fecha de factura no puede ser mayor a la fecha de hoy');
      }
    },
    'summarie.aPagar'() {
      this.restante = JSON.parse(JSON.stringify(this.guardadoRestante))
      this.restante = this.restante - this.summarie.aPagar
    },
    'summarie.Advance'(){
    },
    row(tipo) {
      if (tipo == 0) {
        this.radioAgente = !this.radioAgente
        this.radioProvedor = !this.radioProvedor
        this.supplierId = null;
      }
      if (tipo == 1) {
        this.radioProvedor = !this.radioProvedor
        this.radioAgente = !this.radioAgente
        this.freightForwarderId = null;
      }

    },
    length(val) {
      this.tab = val - 1;
    },
    'shipment.id': {
      handler() {
        this.itemsSolicitudes = []
        this.panel = -1
      },
      deep: true
    },
    shipment: {
      handler() {
        this.panel = [];
        this.getPrice();
        this.cleanSummarie();
        this.currencieSelected = "";
      },
      deep: true,
    },
    'summarie.itemsNC': {
      handler: debouncePromise(function () {
        if (this.summarie.itemsNC.length > 0) {
          this.tryCreditNote = true
        } else {
          this.tryCreditNote = false
        }
        let check = false;
        this.elSuperTotal2 = 0
        this.summarie.itemsNC.forEach((element) => {
          this.elSuperTotal2 += (element.price)
          if (!element.conceptId) {
            check = true;
          }
          if (check) {
            this.isConcept2 = true;
          } else {
            this.isConcept2 = false
          }
        })
        if (this.indexConcept2 !== null) {
          this.searchDebounce2(this.summarie.itemsNC[this.indexConcept2].searchConcetp)
        }
      }, 1500),
      deep: true
    },
    'summarie.Fees': {
      handler: debouncePromise(function () {
        let check = false;
        this.elSuperTotal = 0
        this.summarie.Fees.forEach((element) => {
          this.elSuperTotal += (element.price)
          if (!element.purchaseConceptId) {
            check = true;
          }
          if (check) {
            this.isConcept = true;
          } else {
            this.isConcept = false;
          }
        })
        if (this.indexConcept !== null) {
          if (this.summarie.Fees[this.indexConcept].searchConcetp.length > 3)
            this.searchDebounce(this.summarie.Fees[this.indexConcept].searchConcetp)
        }
      }, 100),
      deep: true,
    },
    date() {
      this.summarie.DueDate = this.date;
    },
    date11() {
      this.summarie.invoiceIssuedAt = this.invoiceIssuedAt;
    },
    date111() {
      this.invoiceIssuedAt = this.invoiceIssuedAtDate;
    },
    summarie: {
      handler() {

      },
      deep: true,
    },
    // 'shipment.shippingStatusId': function (newVal,oldVal) {
    //   if(newVal < 2){
    //     this.$toast.error('El embarque ya esta creado no puede regresar a provisional.');
    //     console.log(oldVal)
    //     this.shipment.shippingStatusId = oldVal;
    //   }
    // }
  },
  computed: {
    ventaTotal() {
      let total = 0
      this.summarie.Fees.forEach((element) => {
        total += element.ratableWeight * element.price + element.profit
      })

      return total;
    },
    totals() {
      console.log('totals')
      const totals = this.summarie.Fees.reduce(
          (acc, d) => {
            d.subTotal = d.ratableWeight > d.quantity ? d.ratableWeight * d.price : d.quantity * d.price;
            acc.quantity += +d.quantity;
            acc.ratableWeight += d.ratableWeight;
            acc.price += d.price;
            acc.subTotal += d.ratableWeight > d.quantity ? d.ratableWeight * d.price : d.quantity * d.price;
            acc.iva += d.purchaseConcept ? this.setIva(d.subTotal, d.purchaseConcept.name) : this.setIva(d.subTotal, d.paymentConcept.name)
            acc.retencionIVA += d.purchaseConcept ? this.setRetencion(d.subTotal, d.purchaseConcept.name) : this.setRetencion(d.subTotal, d.paymentConcept.name)
            acc.retencionISR += d.purchaseConcept ? this.setRetencionISR(d.subTotal, d.purchaseConcept.name) : this.setRetencionISR(d.subTotal, d.paymentConcept.name)
            return acc;
          },
          {
            ratableWeight: 0,
            total: 0,
            quantity: 0,
            price: 0,
            iva: 0,
            retencionIVA: 0,
            retencionISR: 0,
            subTotal: 0,
          }
      );
      totals.total = totals.subTotal + totals.iva - totals.retencionIVA - totals.retencionISR
      return totals;
    },
    totalsNC() {
      const totals = this.summarie.itemsNC.reduce(
          (acc, d) => {
            acc.quantity += d.quantity;
            acc.ventaTotal += d.elTotal;
            acc.elTotal += d.elTotal;
            acc.ratableWeight += d.ratableWeight;
            acc.price += d.price;
            acc.profit += d.profit;
            acc.total += d.total
            return acc;
          },
          {
            ratableWeight: 0,
            ventaTotal: 0,
            quantity: 0,
            profit: 0,
            price: 0,
            total: 0,
            elTotal: 0,
          }
      );
      return totals;
    },
    timeline() {
      return this.events.slice().reverse();
    },
    currentShipingStatus: function () {
      return this.shippingStatus.find(
          (status) => this.shipment.shippingStatusId === status.id
      );
    },
    totalCompras: function () {
      return this.demoCargos.reduce((prev, next) => {
        return prev + parseInt(next.compra);
      }, 0);
    },
    totalVentas: function () {
      return this.demoCargos.reduce((prev, next) => {
        return prev + parseInt(next.venta);
      }, 0);
    },
    totalProfit: function () {
      return {
        profit: Math.abs(this.totalVentas - this.totalCompras),
        isPositive: this.totalVentas > this.totalCompras,
      };
    },
  },
  mounted() {
    //this.getOperators();
    //this.getIncoterms();
    this.getSuppliers();
    //this.getCancelReason();
    this.getForwarders();
    //this.getCustomers();
    //this.getCurrencies();
    //this.getShipping();
    this.getPrice();
    //  this.getConcept();
  },


  methods: {
    numberInputValidation(value) {
      const isGreaterZero = value > 0
      if(isGreaterZero) {
        this.isPurchaseValid = true
        return true
      }

      this.isPurchaseValid = false
      return 'Tiene que ser mayor a 0'
    },
    onRemoveCreditNote(creditNote, index) {
      if(creditNote.id === 0) {
        this.creditNotes.splice(index, 1)
        return
      }
      this.$toast.error("Actualmente, no está soportado eliminar notas de crédito ya creadas. Favor contactar a soporte técnico.");
    },
    mapFileCreditNote(creditNote) {
      if (!creditNote.file) return
      creditNote.fileExtension = creditNote.file[0]?.name.split('.').pop()
      creditNote.fileName = creditNote.file[0]?.name
    },
    addCreditNote(fees = []) {

      if(fees.length === 0) fees.push(this.defaultCreditNoteFee)


      this.creditNotes.push({
        fees: fees,
        totalsNC: {
          subtotal: 0,
        },
        id: 0,
        total: 0,
        shippingPurchaseId: 0,
        paymentRequestId: 0,
        supplierId: 0,
        invoiceNumber: "",
        issuedAt: "",
        file: [],
        fileExtension: "",
        fileName: "",
        docPath: "",
      })
    },
    addCreditNoteFee(creditNote) {
      creditNote.fees.push(this.defaultCreditNoteFee)
    },
    setIva(subTotal, concept) {
      if (concept.includes('*')) {
        if (concept.includes('*/')) {
          let ivaAcreditable = subTotal * 0.25
          return ivaAcreditable * 0.16
        }
        return subTotal * 0.16
      }
      return 0
    },
    setRetencion(subTotal, concept) {
      if (concept.includes('^')) {
        return subTotal * 0.04
      }
      return 0
    },
    setRetencionISR(subTotal, concept) {
      if (concept?.includes('RESICO')) {
        return subTotal * 0.0125
      }
      return 0
    },
    updateConceptSearch: debounce(function (val, item) {

      console.log('updateConceptSearch', val, item)
      item.searchConceptLoading = true
      // if (!item.purchaseConcept) item.conceptItems = []
      const selectedConcept = item.conceptItems?.find(x => x.name === val)
      if (selectedConcept?.name === val) {
        item.searchConceptLoading = false
        console.log('selectedConcept', selectedConcept)
        return
      }
      item.conceptItems = []
      this.searchConcepts(val).then(data => {
        const concepts = data.filter(concept => concept.currencyId === item.currencyId)
        this.globalConcepts = concepts
        item.conceptItems = concepts
        console.log('concepts', concepts)
        console.log('item.conceptItems', item.conceptItems)
      })
      .finally(() => {
        item.searchConceptLoading = false
      })
    }, 200),
    searchConcepts (search) {
      if (!search) {
        return new Promise((resolve) => {
          resolve([])
        })
      }
      return this.getConcept(search)
    },
    getConcept(search) {
      return new Promise((resolve) => {
        this.$http.get(`shipping/payment/concepts?name=${search}`).then((response) => {
          resolve(response.data.data)
        })
      })
    },
    onChangePurchaseFeeConcept(concept, item) {
      item.purchaseConceptId = concept?.id || 0
      item.currencyId = concept?.currencyId || item.currencyId

      if (concept?.ivaAcreditable > 0) {
        item.hasVat = true
      }
    },
    changeCurrency(item) {
      if (item.currencyId !== item.purchaseConcept.currencyId) {
        item.purchaseConcept = null
        item.purchaseConceptId = null
      }
    },
    getCurrentDate() {
      return new Date().toISOString().substr(0, 10); // Returns current date in 'YYYY-MM-DD' format
    },

    validateFields() {
      this.$nextTick(() => {
        this.$refs.paymentForm[0].validate();
      })
    },


    copyFees(index) {
      this.toShow = false;
      console.log(index)
      let feesSummarie = JSON.parse(JSON.stringify(this.summarie.Fees));
      feesSummarie = feesSummarie.map((element) => {
        element.conceptItems.forEach((concept) => {
          this.multipleConceptItems.push(concept)
        })
        console.log(element)
        // let id = element.conceptId.id;
        return {
          ...element,
          concept: element.paymentConcept,
          basePrice: element.total,
          iva: 0,

        }
      })
      this.suppliersSummarie[index].fees = feesSummarie;

      console.log(this.suppliersSummarie[index].fees)
      this.suppliersSummarie[index].fees.forEach((element) => {
        console.log(element)
        this.searchConceptMultiple(element.searchConcetp)
      })
      this.toShow = true;
      /*let arr = []
      this.suppliersSummarie[index].fees.push(arr)
      this.suppliersSummarie[index].fees.splice(-1)*/
    },
    bgColor(item) {
      if (item.feeStatusPaymentId === 1) {
        return 'red lighten-4'
      }
      if (item.feeStatusPaymentId === 2) {
        return 'green lighten-4'
      }
    },
    setIndexes(indexS, index) {
      this.indexSupplier = indexS
      this.indexFee = index
    },
    searchConceptMultiple: debouncePromise(function (search) {
      console.log(search)

      this.$http.get(`shipping/payment/concepts`, {
        params: {
          name: search
        }
      }).then((response) => {
        console.log(response.data.data)
        let toFilter = response.data.data
        // this.multipleConceptItems = []
        toFilter.forEach((element) => {
          if (element.currency === this.currencieSelected) {
            this.multipleConceptItems.push(element)
          }
        })
      })
    }, 500),
    validateCreditNote(creditNote){
      let feesValidations = creditNote.fees.map(fee => {
        return [
          fee.concept && fee.concept.id !== 0 ? "" : "Por favor seleccione un concepto",
          fee.total ? "" : "Por favor ingrese el total",
          fee.currencyId ? "" : "Por favor seleccione una moneda"
        ]
      })
      feesValidations = feesValidations.flat()
      const creditNoteValidations = [
        creditNote.invoiceNumber ? "" : "Por favor ingrese el numero de nota de credito",
        creditNote.issuedAt ? "" : "Por favor ingrese la fecha de la nota de credito",
        this.summarie.id ? "" : "Es necesaria una solicitud de pago",
      ]

      this.creditNoteValidations = [...creditNoteValidations.filter(validation => validation !== ""), ...feesValidations.filter(validation => validation !== "")]
    },
    mapCreditNoteRequest(creditNote, process = ''){
      this.validateCreditNote(creditNote)
      if(this.creditNoteValidations.length > 0){
        return
      }
      return {
        Id: creditNote.id,
        Total: creditNote.total,
        ShippingPurchaseId: this.prices.id,
        SupplierId: this.summarie.supplierId,
        InvoiceNumber: creditNote.invoiceNumber,
        issuedAt: creditNote.issuedAt,
        fileName: creditNote.fileName,
        fileExtension: creditNote.fileExtension,
        docPath: creditNote.docPath,
        Fees: creditNote.fees.map((fee) => {
          return {
            price: fee.total,
            currencyId: fee.currencyId,
            name: fee.name || fee.concept.name,
            total: fee.total,
            feeStatusPurchaseId: 1,
            containerId: 1,
            quantity: fee.quantity === 0 ? 1 : fee.quantity,
            ratableWeight: fee.ratableWeight === 0 ? 1 : fee.ratableWeight,
            conceptId: fee.concept.id,
            purchaseDiscountId: creditNote.id,
            id: process === 'CREATE' ? 0 : fee.id,
          }
        }),
        paymentRequestId: this.summarie.id
      }
    },
    createCreditNote(creditNote) {
      const request = this.mapCreditNoteRequest(creditNote, 'CREATE')
      if(!request){
        return
      }

      this.$store.dispatch("working", true);

      this.$http.post("/Shipping/" + this.shipment.id + '/purchase/' + this.prices.id + '/discounts', request).then(async (response) => {
        if (response.data.code === 200) {
          this.$toast.info("Nota credito creada con exito!");
          if(response.data.data.docPath !== creditNote.docPath){
            alert("Se ha subido un archivo")
            const response = await getS3(response.data.data.docPath, creditNote.file[0]).catch(() => {
              this.$toast.error("Ocurrio un error al subir el archivo")
            })
          }
        } else {
          this.$toast.error("Ocurrio un error");
        }
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.$store.dispatch("working", false);
        setTimeout(() => {
          this.getSolicitudes(this.provedorG, this.indexG);
        }, 1000);
        setTimeout(() => {
          this.getSolicitudDetail(request.paymentRequestId)
        }, 1000);
        
      })
    },
    updateCreditNote(creditNote) {
      const request = this.mapCreditNoteRequest(creditNote)
      if(!request){
        return
      }

      this.$store.dispatch("working", true);

      this.$http.put(`Shipping/${this.shipment.id}/purchase/${this.prices.id}/discounts/${creditNote.id}`, request).then(async (response) => {
        console.log(response)
        if (response.data.code === 200) {
          this.$toast.info("Actualizado con exito!");
          if(response.data.data.docPath !== creditNote.docPath){
            if(creditNote.file[0].size){
              const response = await getS3(response.data.data.docPath, creditNote.file[0])
            }
          }
        } else {
          this.$toast.error("Ocurrio un error");
        }
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.$store.dispatch("working", false);
        this.getPrice();
        this.cleanSummarie();
        console.log(this.provedorG, this.indexG);
        setTimeout(() => {
          console.log("work");
          this.getSolicitudes(this.provedorG, this.indexG);
        }, 1000);
      })
    },
    compareConcepts(item, elIndex) {
      this.summarie.Fees.forEach((element, index) => {
        console.log(JSON.parse(JSON.stringify(item)))
        console.log(element, index, elIndex)
        console.log(JSON.parse(JSON.stringify(this.summarie.Fees)))
      });
    },
    searchDebounce2: debouncePromise(function (search) {
      if (search && search.length >= 1) {
        this.getAllConceptsGeneric(search, 'itemsNC', 'indexConcept2', 'oldSearch2', 'guardoIndex2');
      } else if (search.length === 0) {
        this.$toast.error('Por favor, ingresa un concepto válido.');
      }
    }, 1000),
    searchDebounce: debouncePromise(function (search) {
      if (search && search.length >= 1) {
        this.getAllConceptsGeneric(search, 'Fees', 'indexConcept', 'oldSearch', 'guardoIndex');
      } else if (search.length === 0) {
        this.$toast.error('Por favor, ingresa un concepto válido.');
      }
    }, 100),
    getAllConceptsGeneric(search, itemsProperty, indexConceptProperty, oldSearchProperty, guardoIndexProperty) {
      console.log('Busqueda iniciada:', search);

      if (search !== null && search !== '') {
        this.loading = true;
        const currentGuardoIndex = JSON.parse(JSON.stringify(this[indexConceptProperty]));
        const currentOldSearch = this[oldSearchProperty];

        if (currentOldSearch !== search || currentGuardoIndex !== this[indexConceptProperty]) {
          this[guardoIndexProperty] = currentGuardoIndex;
          this[oldSearchProperty] = search;

          this.fetchConcepts(search, itemsProperty, indexConceptProperty)
              .then(response => {
                console.log('Respuesta recibida:', response);
                this.isLoading = false;  // Agrega esta línea

                if (response.data.data.length === 0) {
                  this.$toast.error('No se encontraron conceptos.');
                }
              })
              .catch(error => {
                console.error('Error en la búsqueda:', error);
                this.$toast.error('No hay conceptos generales');
                this.isLoading = false;  // Agrega esta línea
              });
        } else {
          console.log('Busqueda duplicada, no se realizó una nueva búsqueda.');
        }

        console.log('AllConcepts', this.summarie[itemsProperty]);
      }
    },
    fetchConcepts(search, itemsProperty, indexConceptProperty) {
      console.log('Realizando búsqueda con:', search);

      return this.$http.get(`shipping/payment/concepts`, {params: {name: search}})
          .then(response => {
            const toFilter = response.data.data;
            this.summarie[itemsProperty][this[indexConceptProperty]].conceptItems = [];

            if (toFilter.length > 0) {
              toFilter.forEach(element => {
                if (element.currency === this.currencieSelected) {
                  this.summarie[itemsProperty][this[indexConceptProperty]].conceptItems.push(element);
                }
              });
            } else {
              this.$toast.error('No se encontraron conceptos.');
            }

            console.log(response);
            return response;
          });
    },


    getAllConcepts2(search) {
      if (search !== null) {
        if (this.oldSearch2 === null) {
          this.guardoIndex2 = JSON.parse(JSON.stringify(this.indexConcept2))
          this.oldSearch2 = search
          this.$http.get(`shipping/payment/concepts`, {
            params: {
              name: search
            }
          }).then((response) => {
            let toFilter = response.data.data
            this.summarie.itemsNC[this.indexConcept2].conceptItems = []
            console.log(toFilter)
            toFilter.forEach((element) => {
              if (element.currency === this.currencieSelected) {
                this.summarie.itemsNC[this.indexConcept2].conceptItems.push(element)
              }
            })
            console.log(response)
          }).catch((error) => {
            console.log(error)
          })
        }
        if (this.oldSearch2 !== search && this.guardoIndex2 !== this.indexConcept2) {
          this.guardoIndex2 = JSON.parse(JSON.stringify(this.indexConcept2))
          this.oldSearch2 = search
          this.$http.get(`shipping/payment/concepts`, {
            params: {
              name: search
            }
          }).then((response) => {
            let toFilter = response.data.data
            this.summarie.itemsNC[this.indexConcept2].conceptItems = []
            console.log(toFilter)
            toFilter.forEach((element) => {
              if (element.currency === this.currencieSelected) {
                this.summarie.itemsNC[this.indexConcept2].conceptItems.push(element)
              }
            })
            console.log(response)
          }).catch((error) => {
            console.log(error)
            this.$toast.error('No hay conceptos generales');
          })
        }
        if (this.guardoIndex2 !== this.indexConcept2 && this.oldSearch2 === search) {
          this.guardoIndex2 = JSON.parse(JSON.stringify(this.indexConcept2))
          this.oldSearch2 = search
          this.$http.get(`shipping/payment/concepts`, {
            params: {
              name: search
            }
          }).then((response) => {
            let toFilter = response.data.data
            this.summarie.itemsNC[this.indexConcept2].conceptItems = []
            console.log(toFilter)
            toFilter.forEach((element) => {
              if (element.currency === this.currencieSelected) {
                this.summarie.itemsNC[this.indexConcept2].conceptItems.push(element)
              }
            })
            console.log(response)
          }).catch((error) => {
            console.log(error)
            this.$toast.error('No hay conceptos generales');
          })
        }
        if (this.guardoIndex2 === this.indexConcept2 && this.oldSearch2 !== search) {
          this.guardoIndex2 = JSON.parse(JSON.stringify(this.indexConcept2))
          this.oldSearch2 = search
          this.$http.get(`shipping/payment/concepts`, {
            params: {
              name: search
            }
          }).then((response) => {
            let toFilter = response.data.data
            this.summarie.itemsNC[this.indexConcept2].conceptItems = []
            console.log(toFilter)
            toFilter.forEach((element) => {
              if (element.currency === this.currencieSelected) {
                this.summarie.itemsNC[this.indexConcept2].conceptItems.push(element)
              }
            })
            console.log(response)
          }).catch((error) => {
            console.log(error)
            this.$toast.error('No hay conceptos generales');
          })
        }
      }
    },
    habrirModal() {
      this.dialogImport = true;
    },
    cambio(tipo) {
      if (tipo == 0) {
        this.radioAgente = !this.radioAgente
        this.radioProvedor = !this.radioProvedor
        this.supplierId = null;
      }
      if (tipo == 1) {
        this.radioProvedor = !this.radioProvedor
        this.radioAgente = !this.radioAgente
        this.freightForwarderId = null;
      }
    },

    deleteRow(item) {
      // let index = this.prices.findIndex(x=> x.id === this.shipmentId)
      // el codigo de arriba fue donde mireles me enseño -Quintero
      // realmente lo de abajo no se necesita porque ya tengo el id del fee pero era para practicar xd
      console.log(item);
      let index = this.sales.details[0].fees.findIndex((x) => x.id === item.id);
      if (index === -1) {
        this.$toast.error("No se pudo eliminar :(");
      } else {
        this.$http
            .delete(
                `shipping/${this.shipment.id}/sale/details/${this.sales.details[0].id}/fee/${item.id}`
            )
            .then((response) => {
              console.log(response);
            });
      }
    },

    enviarEvidencia() {
      this.aux = [];
      let doc2 = {
        doc2: this.edit.documents,
      };
      console.log(doc2);

      this.$store.dispatch("working", true);
      this.$http
          .post(
              `Shipping/${this.shipment.id}/payment/${this.prices.id}/detail/${this.paymentId}/change`,
              this.edit.documents
          )
          .then((response) => {
            console.log(response);
            this.$toast.info("Ya puede editar la compra");
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("Ocurrio un error");
          })
          .finally(() => {
            this.getPrice();
            this.getSolicitudes(this.provedorG, this.indexG);
            this.$store.dispatch("working", false);
          });
      // console.log(this.prices.details[0].shippingPurchaseId)
      this.dialogEdit = false;
      this.confirmPrices = false;
      this.edit.comments = "";
      this.edit.documents = [];
      this.docLleno = false;
    },
    deleteFeeNC(creditNote, index) {
      creditNote.fees.splice(index, 1)
    },
    delteSummarieFee(item, index1) {
      item.selected = false
      //this.send2Summary(item)
      console.log(item)
      let index = this.prices.details[this.indexG].fees.findIndex(x => x.id === item.PurchaseFeeId)
      this.summarie.Fees.splice(index1, 1)
      console.log(index)
      this.prices.details[this.indexG].fees[index].selected = false;
    },
    mapUpdatePaymentRequest(paymentRequest){
      return {
        id: paymentRequest.id,
        purchaseDetailId: paymentRequest.purchaseDetailId,
          urgent: paymentRequest.Urgent,
          invoiceIssuedAt: paymentRequest.invoiceIssuedAt,
          invoiceNumber: paymentRequest.invoiceNumber,
          advance: paymentRequest.Advance,
          totalAdvance: paymentRequest.TotalAdvance,
          creditDays: paymentRequest.CreditDays,
          supplierId: this.row === 1 ? null : paymentRequest.supplierId,
          freightForwarderId: this.row === 2 ? null : paymentRequest.freightForwarderId,
          payTo: paymentRequest.payTo,
          dueDate: paymentRequest.DueDate,
          prePaid: paymentRequest.prePaid,
          notes: paymentRequest.notes,
          paymentType: paymentRequest.paymentType,
          notification: this.notification,
          fees: paymentRequest.Fees.map((fee) => {
            return {
              id: fee.id,
              paymentRequestId: paymentRequest.id,
              purchaseFeeId: fee.PurchaseFeeId ?? fee.purchaseFeeId,
              price: fee.price,
              currencyId: fee.currencyId,
              conceptId: fee.purchaseConcept.id,
              quantity: fee.quantity,
              hasVat: fee.hasVat,
              hasRet: fee.hasRet,
              name: fee.purchaseConcept.name,
              ratableWeight: fee.ratableWeight,
              total: fee.total,
              containerId: fee.containerId === null ? 1 : fee.containerId
            }
          }),
          documents: paymentRequest.documents.length == 0 ? null : paymentRequest.documents.map((doc) => {
            return {
              id: doc.id,
              documentTypeId: doc.documentTypeId,
              paymentRequestId: paymentRequest.id,
              fileName: doc.fileName,
              fileExtension: doc.fileExtension,
              file: doc.file
            }
          }),
          suppliers: paymentRequest.suppliers.length == 0 ? null : paymentRequest.suppliers.map((supplier) => {
            return {
              id: supplier.id,
              paymentRequestId: paymentRequest.id,
              supplierId: supplier.supplierId,
              forwarderId: supplier.forwarderId,
              invoiceNumber: supplier.invoiceNumber,
              amount: supplier.amount,
              invoiceDate: supplier.invoiceDate,
              invoiceFileName: supplier.invoiceFileName,
              invoiceFileExtension: supplier.invoiceFileExtension,
              fees: supplier.fees.map((fee) => {
                return {
                  id: fee.id ?? 0,
                  paymentRequestSupplierId: supplier.id,
                  conceptId: fee.concept.id,
                  name: fee.concept.name,
                  quantity: fee.quantity,
                  basePrice: fee.basePrice,
                  iva: fee.iva,
                  ivaAcreditable: fee.ivaAcreditable,
                  retIva: fee.retIva,
                  retIsr: fee.retIsr,
                  ledgerAccount: fee.ledgerAccount
                }
              })
            }
          })
        }
    },
    async updateSummarie() {
      if (!this.summarie.Fees.every(x => x.purchaseConcept.name.includes("GARANTIA"))) {
          if(this.summarie.invoiceNumber === null || this.summarie.invoiceIssuedAt === null) return this.$toast.error("Favor de llenar los datos de factura");
        }
      this.summarie.suppliers = this.suppliersSummarie.every(x => x.supplierId !== 0) ? this.suppliersSummarie : [];
      let request = this.mapUpdatePaymentRequest(this.summarie);

      request = await this.checkInvoiceNumberStatus(request)
      console.log(request)
      if (this.editFees) {
        console.log(this.storedFees)
        this.storedFees.forEach((element) => {
          request.Fees.forEach((fee) => {
            if (element.purchaseFeeId === fee.PurchaseFeeId) {
              fee.id = element.id
            }
          })
        })
      }
      console.log(request)
      if (!request) {
        this.$store.dispatch("working", false);
        return
      }

      this.$store.dispatch("working", true);
      this.$http.put(`shipping/${this.prices.shippingId}/payment/${this.summarie.id}`, request).then((response) => {
        if (response.data.code === 200) {
          this.$toast.info("Confirmado con exito!");
          /*response.data.data.suppliers.forEach(async (supplier, index) => {
            this.getS3(supplier.invoiceDocPath, index);
          })*/
          this.getS3(response.data.data.suppliers)
          this.summarie.documents.forEach((doc) => {
            response.data.data.documents.forEach(async (document) => {
              if (doc.fileName === document.fileName) {
                await getS3(document.path, doc.file).catch(() => {
                  this.$toast.error("Ocurrio un error al subir el archivo")
                })
              }
            })
          })
        } else {
          this.$toast.error("Ocurrio un error");
        }
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.paymentUpdate = false;
        this.cleanSummarie()
        this.getPrice();
        this.getSolicitudes(this.provedorG, this.indexG);
        this.$store.dispatch("working", false);
      })
    },
    async getS3(suppliers) {
      this.suppliersSummarie.forEach(async (supplier, index) => {
        console.log(supplier)
        if (supplier.invoiceDocAux) {
          const response = await getS3(suppliers[index].invoiceDocPath, supplier.invoiceDocAux[0]);
          console.log(response);
          /*console.log(supplier.invoiceDocAux[0])
          console.log(index);
          const url = suppliers[index].invoiceDocPath;
          const objURL = URL.createObjectURL(supplier.invoiceDocAux[0]);
          const elblob = await fetch(objURL).then(res => res.blob());
          let generatedURL = null;
          await this.$http.get('shipping/generate/signed-url', {
            params: {
              path: url
            }
          }).then((response) => {
            generatedURL = response.data
          })

          // axios.defaults.baseURL = '';
          const resp = await fetch(generatedURL, {
            method: 'PUT',
            body: elblob,
            headers: {
              'Content-Type': supplier.invoiceDocAux[0].type
            }
          })
          console.log(resp)*/
        }
      })
    },
    /* async getS3(pathString, index) {
       await this.$http.get('shipping/generate/signed-url', {
         params: {
           path: pathString
         }
       }).then((response) => {
         console.log(response)
         console.log(index);
         // response.data
         // this.docsURL.push(response.data)
         // this.uploadFile(response.data, index)
       })
     },*/
    /*async uploadFile(file, index) {

    },*/
    /* downloadAll(price) {
      price.priceDocuments.forEach((pDocument) => {
        this.downloadDocument(pDocument)
      })
    },*/
    downloadDocument(doc) {
      console.log(doc, "soy jost")
      console.log(this.prices)
      this.$store.dispatch("working", true);
      this.$http({
        url: `Shipping/${this.shipment.id}/purchaseDetail/${doc.purchaseDetailId}/Document/${doc.id}`,
        method: "GET",
        responseType: "blob", // important
      })
          .then((response) => {
            console.log(response);
            fileDownload(response.data, `${doc.fileName}.${doc.fileExtension}`);
            this.$toast.info('Pdf Generado');
            /* var file = new Blob([response.data], {type: 'application/pdf'});
             var fileURL = URL.createObjectURL(file);
             window.open(fileURL);*/
          })
          .catch(() => {
            this.$toast.error("Algo salio mal, intente mas tarde");
            // console.log(error);
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });
    },
    filterCurrency() {
      this.itemsFiltered = [];
      this.itemsFiltered = this.itemsConcept.filter((item) => {
        return item.currency === this.currencieSelected;
      });
    },
    getSolicitudes(provedor, index) {
      // esta 2 se usan para updatear cuando se haga una solicitud (sendSummary)

      this.provedorG = provedor;
      this.indexG = index;
      // this.prices.discounts = []
      //
      this.$store.dispatch("working", true);
      this.$http
          .get(
              `shipping/${this.prices.shippingId}/purchase/${this.prices.id}/detail/${provedor.id}/payments`
          )
          .then((response) => {
            this.itemsSolicitudes = response.data.data;
            // solo diosito y yo sabiamos que hacia este codigo, ahora solo lo sabe diosito 🥵
            // -Quintero xd
            // lo vuelvo a ver un tiempo despues y tan perro no esta alv
            this.prices.details.forEach((provedor, indexProv) => {
              provedor.fees.forEach((feeProvedor, indexfee) => {
                this.itemsSolicitudes.forEach((solicitud) => {
                  solicitud.fees.forEach((feeSolicitud) => {
                    if (feeProvedor.id === feeSolicitud && solicitud.paymentStatus.id !== 4) {
                      this.prices.details[indexProv].fees[
                          indexfee
                          ].disable = true;
                    }
                  });
                });
              });
            });
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });
    },
    importarFactura() {
      /*      this.aux = [];
            let doc2 =
              {
                doc2: this.edit.documents,
                invoiceNumberFactura: this.invoiceNumberFactura,
                totalAmount: this.totalAmount,
              }
            console.log(doc2);*/

      this.$store.dispatch("working", true);
      this.$http
          .post(
              `Shipping/${this.shipment.id}/payment/${this.paymentId}/attachFiles`,
              this.facturaImportar
          )
          .then((response) => {
            console.log(response);
            this.$toast.info("Factura importada con exito");
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("Ocurrio un error");
          })
          .finally(() => {
            setTimeout(() => {
              this.getPrice();
              this.getSolicitudes(this.provedorG, this.indexG);
              this.cleanSummarie()
              this.$store.dispatch("working", false);
            }, 1500)
          });
      // console.log(this.prices.details[0].shippingPurchaseId)
      this.dialogEdit = false;
      this.confirmPrices = false;
      this.edit.comments = "";
      this.edit.documents = [];
      this.docLleno = false;
      this.invoiceIssuedAtDate = null;
      this.dialogImport = false;
    },
    async converFileImportar() {
      let documents = this.fileDragImportar

      for (const doc of documents) {
        const result = await this.UploadFile(doc);
        const data = result.split(",")[1];
        const dotPosition = doc.name.lastIndexOf(".");
        const fileExt = doc.name.substr(dotPosition + 1);
        const fileName =
            dotPosition === -1 ? doc.name : doc.name.substr(0, dotPosition);

        this.facturaImportar.push(
            {
              documentTypeId: 103,
              id: 0,
              file: data,
              fileName: fileName,
              fileExtension: fileExt,
              invoiceIssuedAt: this.importarData.invoiceIssuedAt,
              invoiceNumber: this.importarData.invoiceNumber,
              totalAmount: this.importarData.totalAmout
            }
        )
      }
    },
    async ConvertFileSummarie(supplier, index) {
      let documents = supplier.invoiceDocAux;
      console.log(supplier, index);
      console.log(supplier);
      for (const doc of documents) {
        // const result = await this.UploadFile(doc)
        // console.log(result);
        // const data = result.split(",")[1];
        const dotPosition = doc.name.lastIndexOf(".");
        const fileExt = doc.name.substr(dotPosition + 1);
        const fileName =
            dotPosition === -1 ? doc.name : doc.name.substr(0, dotPosition);
        /*this.suppliersSummarie[index].invoiceDoc.push({
          id: 0,
          documentTypeId: 103,
          invoiceFileName: fileName,
          invoiceFileExtension: fileExt,
          shippingPurchaseId: 0,
        })*/
        this.suppliersSummarie[index].invoiceFileName = fileName;
        this.suppliersSummarie[index].invoiceFileExtension = fileExt;
        this.suppliersSummarie[index].eldoc = documents[0];
      }
    },
    ConvertFile4: async function () {
      let documents = this.fileDrag4;

      for (const doc of documents) {
        const result = await this.UploadFile(doc);
        const data = result.split(",")[1];
        const dotPosition = doc.name.lastIndexOf(".");
        const fileExt = doc.name.substr(dotPosition + 1);
        const fileName =
            dotPosition === -1 ? doc.name : doc.name.substr(0, dotPosition);
        this.summarie.documents.push({
          id: 0,
          documentTypeId: 103,
          file: data,
          fileName: fileName,
          fileExtension: fileExt,
          shippingPurchaseId: 0,
          invoiceNumber: this.summarie.invoiceNumber,
          invoiceIssuedAt: this.summarie.invoiceIssuedAt,
          totalAmount: parseFloat(this.summarie.totalAmount),

        });
        /*this.summarie.suppliers.forEach((element, index) => {
          this.summarie
        })*/
        /*  this.edit.documents.documentTypeId = 103;
        (this.edit.documents.file = data),
          (this.edit.documents.fileName = fileName);
        this.edit.documents.fileExtension = fileExt; */
      }
    },
    ConvertFile5: async function () {
      let documents = this.fileDrag4;

      for (const doc of documents) {
        const result = await this.UploadFile(doc);
        const data = result.split(",")[1];
        const dotPosition = doc.name.lastIndexOf(".");
        const fileExt = doc.name.substr(dotPosition + 1);
        const fileName =
            dotPosition === -1 ? doc.name : doc.name.substr(0, dotPosition);
        this.summarie.documents.push({
          id: 0,
          documentTypeId: 103,
          file: data,
          fileName: fileName,
          fileExtension: fileExt,
          shippingPurchaseId: 0,
        });
        /*  this.edit.documents.documentTypeId = 103;
        (this.edit.documents.file = data),
          (this.edit.documents.fileName = fileName);
        this.edit.documents.fileExtension = fileExt; */
      }
    },
    //getConcept() {
    //  this.$http
    //      .get(`shipping/payment/concepts?pageSize=339`)
    //      .then((response) => {
    //        this.itemsConcept = response.data.data;
    //      });
    //},
    getSuppliers() {
      this.$http.get("Suppliers").then((response) => {
        this.itemsSuppliers = response.data.data;
      });
    },
    getCancelReason() {
      this.$http.get("shipping/payment/cancel/reasons").then((response) => {
        this.itemsCancelReasons = response.data.data;
      });
    },
    cancelSolicitud() {
      this.dialogEdit3 = true;
      this.getCancelReason();
    },
    cancelSolicitud2() {
      this.$store.dispatch("working", true);
      let cancelObj = {
        paymentRequestId: this.paymentId,
        description: this.commentCancel,
        paymentCancellationReasonId: this.cancelReason,
      };
      console.log(cancelObj);

      this.$http
          .put(
              `shipping/${this.prices.shippingId}/payment/${this.paymentId}/canceled`,
              cancelObj
          )
          .then((response) => {
            if(response.data.code === 400){
              let message = response.data.message.errors[0][0].message.match(/The payment request has a discount, it can't be canceled/)[0];
              return this.$toast.error(message);
            }
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.dialogEdit3 = false;
            this.getPrice();
            this.cleanSummarie();
            console.log(this.provedorG, this.indexG);
            setTimeout(() => {
              console.log("work");
              this.getSolicitudes(this.provedorG, this.indexG);
            }, 1000);
            this.$store.dispatch("working", false);
          });
    },
    cleanSummarie2(item) {
      this.prices.details.forEach((element) => {
        element.fees.forEach((fee) => {
          if (fee.id !== item.id) {
            fee.selected = false;
          }
        });
      });
      this.isFacturacion = false;
      this.suppliersSummarie = [
        {
          rowSummarie: 1,
          id: 0,
          isLast: true,
          isFirst: true,
          supplierId: 0,
          forwarderId: 0,
          invoiceDocAux: [],
          invoiceNumber: null,
          amount: null,
          invoiceDate: null,
          invoiceDoc: [],
          menu: false,
          date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        }
      ]
      this.summarie.Fees = [];
      this.creditNotes = [];
      this.summarie.supplierId = null;
      this.summarie.freightForwarderId = null;
      this.summarie.total = 0;
      this.summarie.invoiceIssuedAt = null;
      this.summarie.TotalAdvance = 0;
      this.summarie.payTo = "";
      this.summarie.status = "No hay solicitud";
      this.DueDate = "2022-01-18";
      this.date = this.summarie.DueDate;
      this.invoiceNumber = this.summarie.invoiceNumber;
      this.sFecha = true;
      this.eEmbarque = false;
      this.summarie.documents = [];
      this.fileDrag4 = [];
      this.currencieSelected = "";
    },
    cleanSummarie() {
      this.summarie.issuedAt = null;
      this.storedFees = []
      this.editFees = false;
      this.tryCreditNote = false;
      this.isFacturacion = false;
      this.suppliersSummarie = [
        {
          fees: [{
            concept: null,
            searchConcept: null,
            conceptId: null,
            name: '',
            quantity: 1,
            basePrice: 1,
            iva: 0,
            ivaAcreditable: "0",
            retIva: 0,
            retIsr: 0,
            ledgerAccount: 0
          }],
          rowSummarie: 1,
          id: 0,
          isFirst: true,
          isLast: true,
          supplierId: 0,
          forwarderId: 0,
          invoiceNumber: null,
          totalAmount: 0,
          invoiceDate: null,
          invoiceDocAux: [],
          invoiceFileName: null,
          invoiceFileExtension: null,
          menu: false,
          date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        }
      ]
      this.summarie.itemsNC = [];
      this.isCreditNote = false;
      this.isFacturacion = false;
      this.isSolicitud = false
      this.oldSearch = null
      this.oldSearch2 = null
      this.summarie.isNumberOrDate = false
      this.summarie.isFirt = true
      this.summarie.aPagar = 0
      this.restante = 0
      this.isDetail = false
      if (this.prices.details) {
        this.prices.details.forEach((element) => {
          element.fees.forEach((fee) => {
            fee.selected = false;
          });
        });
      }
      this.date = new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10),
          this.summarie.Advance = false;
      this.summarie.invoiceIssuedAt = null;
      this.summarie.invoiceNumber = null;
      this.summarie.Id = 0;
      this.summarie.id = 0;
      this.summarie.DueDate = new Date().toISOString().slice(0, 10);
      this.summarie.Fees = [];
      this.summarie.supplierId = null;
      this.summarie.TotalAdvance = 0;
      this.summarie.Description = "";
      this.summarie.status = "No hay solicitud";
      this.DueDate = new Date().toISOString().slice(0, 10);
      this.date = new Date().toISOString().slice(0, 10);
      this.invoiceIssuedAt = this.summarie.invoiceIssuedAt;
      this.sFecha = true;
      this.eEmbarque = false;
      this.summarie.documents = [];
      this.fileDrag4 = [];
      this.currencieSelected = "";
      this.summarie.freightForwarderId = null;
      this.summarie.total = 0,
          this.summarie.supplierId = null;
      this.summarie.notes = "";
      this.summarie.totalAmount = 0
      this.creditNotes = []
    },
    filterOrders() {
      let apiUrl = "shipping/Find";
      this.$http
          .get(apiUrl, {})
          .then((response) => {
            console.log(response.data.data);
            this.Data = response.data.data;
            console.log(this.Data)
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
          });
    },
    asociar() {
      this.$http.put(`shipping/purchase/${this.prices.id}/consolidate`, this.Shp
      );
    },
    GeneateZip(item) {
      this.$store.dispatch("working", true);
      this.paymentId = item.id;
      this.$http({
        url: `https://api-test.senator-int.com/api/payment/getCostExternal?pid=${this.paymentId}`,
        method: "GET",
        responseType: "blob", // important
      })
          .then((response) => {
            console.log(response.data);
            fileDownload(response.data, `${this.shipment.refCode}.html`);
            this.$toast.info('Pdf Generado');
            //var file = new Blob([response.data], {type: 'application/pdf'});
            // var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
          })
          .catch(() => {
            this.$toast.error("Ocurrio un error");
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });
    },

    getCost(item) {
      this.$store.dispatch("working", true);
      this.paymentId = item.id;
      console.log(this.paymentId)
      this.$http({
        url: `https://sadmin.senator-int.com/api/payment/getCostExternal?pid=${this.paymentId}`,
        method: "GET",
      })

          .then((response) => {
            let iframe = document.createElement("iframe");
            iframe.srcdoc = response.data;
            iframe.style.display = "none";
            iframe.onload = function () {
              setTimeout(function () {
                iframe.focus();
                iframe.contentWindow.print();
              }, 1);
            };
            document.body.appendChild(iframe); //load content in an iframe to print later
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });
    },

    getSolicitudDetail(paymentId) {
      this.cleanSummarie();
      console.log(this.summarie.Advance, "aaaaaa")
      this.$http
          .get(
              `shipping/${this.prices.shippingId}/paymentRequest/${paymentId}`
          )
          .then((response) => {

            console.log(response)
            this.isSolicitud = true
            // Aqui se mapean los datos para mostrarlos en summary
            this.suppliersSummarie = [
              {
                isFirst: true,
                isLast: true,
                fees: [],
                rowSummarie: 1,
                id: 0,
                supplierId: 0,
                invoiceDocAux: [],
                invoiceNumber: null,
                amount: null,
                invoiceDate: null,
                invoiceDoc: [],
                menu: false,
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
              }
            ]
            this.summarie.isFirt = false
            this.summarie.Fees = response.data.data.fees;

            let id = this.summarie.Fees[0].paymentConcept.currency.code;
            this.currencieSelected = id;
            this.filterCurrency();
            this.summarie.purchaseDetailId = response.data.data.purchaseDetailId
            this.summarie.id = response.data.data.id
            console.log(response.data.data.invoiceIssuedAt)
            let test = DateTime.fromISO(response.data.data.invoiceIssuedAt).plus({days: 1}).toISODate()
            this.summarie.invoiceIssuedAt = test

            this.summarie.Advance = response.data.data.advance;
            this.summarie.supplierId = response.data.data.supplierId;
            if (response.data.data.freightForwarderId === null) {
              this.row = 2
            }
            if (response.data.data.supplierId === null) {
              this.row = 1
            }
            this.summarie.freightForwarderId = response.data.data.freightForwarderId;
            this.summarie.supplierId = response.data.data.supplierId;
            this.summarie.Id = response.data.data.id
            this.summarie.TotalAdvance = response.data.data.totalAdvance;
            this.restante = 0
            this.summarie.Fees.forEach((element) => {
              this.restante = this.restante + element.total
            })
            this.summarie.Fees = this.summarie.Fees.map((element, index) => {
              let obj = {
                name: this.summarie.Fees[index].paymentConcept.name,
                id: this.summarie.Fees[index].paymentConcept.id,
              }
              return {
                isSolicitud: true,
                conceptId: this.summarie.Fees[index].paymentConcept,
                ...element,
                searchConcetp: null,
                conceptItems: [obj],
                ventaTotal: 0,
                elTotal: element.ratableWeight * element.price,
                purchaseConcept: element.paymentConcept
              }
            })
            this.guardadoRestante = JSON.parse(JSON.stringify(this.restante))
            // this.restante = response.data.data.totalAdvance - this.restante;
            this.summarie.total = response.data.data.total;
            this.summarie.status = response.data.data.statusPayment.description;
            this.summarie.notes = response.data.data.notes;
            this.summarie.freightForwarderId = response.data.data.freightForwarderId

            if (!response.data.data.dueDate) {
              this.credit = true;
              this.setPaymentDate();
            } else {
              this.summarie.DueDate = response.data.data.dueDate;
              this.summarie.DueDate = this.$luxon(
                  this.summarie.DueDate,
                  "yyyy-MM-dd"
              );
              this.date = this.summarie.DueDate;

            }

            // this.summarie.invoiceIssuedAt = response.data.data.invoiceIssuedAt;
            this.summarie.invoiceNumber = response.data.data.invoiceNumber;

            this.summarie.invoiceIssuedAt = this.$luxon(
                this.summarie.invoiceIssuedAt,
                "yyyy-MM-dd"
            );
            this.invoiceIssuedAt = this.summarie.invoiceIssuedAt;
            if (this.summarie.DueDate) {
              this.sFecha = true;
              this.eEmbarque = false;
            }
            if (response.data.data.suppliers.length > 0) {
              this.isFacturacion = true
              this.suppliersSummarie = response.data.data.suppliers
              this.suppliersSummarie.forEach((element, index) => {
                this.suppliersSummarie[index].fees = this.suppliersSummarie[index].fees.map((element) => {
                  this.multipleConceptItems.push(element.purchaseConcept)
                  return {
                    ...element,
                    concept: element.purchaseConcept
                  }
                })
              })
              this.setFirstLast(this.suppliersSummarie);
              this.suppliersSummarie.forEach((element, index) => {
                element.invoiceDocAux = []
                if (!element.forwarderId) {
                  this.suppliersSummarie[index].rowSummarie = 2
                }
                if (!element.supplierId) {
                  this.suppliersSummarie[index].rowSummarie = 1
                }
                /*element.fees.forEach((fee) => {
                  fee.searchConcept = fee.name
                })*/
              })
            } else {
              this.suppliersSummarie[0].fees = [
                {
                  concept: null,
                  searchConcept: null,
                  conceptId: null,
                  name: '',
                  quantity: 1,
                  basePrice: 1,
                  iva: 0,
                  ivaAcreditable: "0",
                  retIva: 0,
                  retIsr: 0,
                  ledgerAccount: 0
                }
              ]
            }

            this.defaultCreditNoteFee = {
              id: 0,
              purchaseDiscountId: 0,
              purchaseFeeId: 0,
              price: 0,
              currencyId: this.summarie.Fees[0].paymentConcept.currency.id,
              quantity: 0,
              hasVat: false,
              hasRet: false,
              name: "",
              ratableWeight: 0,
              searchConceptLoading: false,
              total: 0,
              conceptId: 0,
              containerId: 0,
              feeStatusPurchaseId: 0,
              sendNC: false,
              conceptItems: [],
              searchConcept: "",
              concept: {
                id: 0,
                name: "",
              }
            }

            if (response.data.data.creditNote && this.summarie.itemsNC.length <= 0) {
              this.creditNotes = this.prices.discounts.map(discount => {
                discount.fees = discount.fees.map(fee => {
                  return {
                    ...fee,
                    conceptItems: [],
                    concept: fee.paymentConcept,
                  }
                })
                this.creditNoteTotals(discount)
                return {
                  ...discount,
                  issuedAt: this.$luxon(discount.issuedAt, "yyyy-MM-dd"),
                  file: discount.docPath ? [
                    {
                      name: discount.docPath.substring(discount.docPath.lastIndexOf('/') + 1)
                    }
                  ] : []
                }
              })
              this.isCreditNote = true;
            } else {
              this.summarie.itemsNC = []
            }

          });
      if (this.summarie.freightForwarderId) {
        this.row = 1
      }
      if (this.summarie.supplierId) {
        this.row = 2
      }
      this.isDetail = true;

    },
    creditNoteTotals(discount){
      let iva = 0
      let retIva = 0
      let retIsr = 0
      let subtotal = 0
      discount.fees.forEach((fee) => {
        subtotal += fee.total
        iva += this.setIva(fee.total, fee.concept.name)
        retIva += this.setRetencion(fee.total, fee.concept.name)
        retIsr += this.setRetencionISR(fee.total, fee.concept.name)
      })

      let total = parseFloat(subtotal) + parseFloat(iva) - parseFloat(retIva) - parseFloat(retIsr)

      discount.totalsNC = {
        iva: iva,
        retIva: retIva,
        retIsr: retIsr,
        subtotal: subtotal,
        total: total
      }
    },
    confirmPrice2(id) {
      this.$store.dispatch("working", true);

      // Las siguientes 3 lineas es para que vue/vuetify puedan leer el update de confirm
      // no mover o deja de funcionar xd -Quintero
      let arr = [];
      this.prices.details.push(arr);
      this.prices.details.splice(-1);
      let doc = {
        /* id: this.docConfirm[0].id,
           documentTypeId: this.docConfirm[0].documentTypeId,
           file: this.docConfirm[0].file,
           fileExtension: this.docConfirm[0].fileExtension,
           fileName: this.docConfirm[0].fileName,*/
      };
      this.$http
          .put(
              `shipping/${this.prices.shippingId}/purchase/${this.prices.id}/detail/${id}/confirm`,
              doc
          )
          .then((response) => {
            console.log(response.data.data);
            if (response.data.code === 200) {
              this.$toast.info("Confirmado con exito!");
            } else {
              this.$toast.error("Ocurrio un error");
            }
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("Ocurrio un errorr");
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });
    },
    addSupplier() {
      this.$store.dispatch("working", true);
      console.log(this.prices);
      let provedor = {
        id: 0,
        name: "string",
        supplierId: null,
        freightForwarderId: 1,
        shippingPurchaseId: this.prices.id,
        feesConfirmation: true,
        isEditable: true,
        additionalInfo: {
          airWayBillNo: '',
          reservationno: '',
          purchaseDetailId: 0,
          numPlaca: "string",
          numCaja: "string",
          flightNumber: "string",
          daysOfDelay: 0,
          supplier: {
            companyName: "string",
            description: "string",
            id: 0,
            phone: "string",
            supplierTypeId: 0,
            taxNumber: "string",
            type: {
              description: "string",
              id: 0,
              status: true
            },
          },
        },
        fees: [
          {
            id: 0,
            status: true,
            name: "string",
            purchaseDetailId: 0,
            price: 0,
            total: 0,
            ratableWeight: 1,
            currencyId: 1,
            containerId: 1,
            quantity: 1,
            hasRet: true,
            isAdditionalCharge: false,
            hasVat: true,
          }
        ],
      };
      this.$http
          .post(
              `Shipping/${this.shipment.id}/purchase/${this.prices.id}/supplier`,
              provedor
          )
          .then((response) => {
            if (response.data.code === 200) {
              this.$toast.info("Provedor añadido con exito!");
            }
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("No se pudo agregar 😔");
          })
          .finally(() => {
            this.getPrice();
            this.$store.dispatch("working", false);
          });
    },
    operDialogD(item) {
      console.log(item)
      this.Supplyer2Delete = item
      this.dialogDelit = true


    },

    deleteSupplier(item) {
      this.$store.dispatch("working", true);
      console.log(item);
      this.$http
          .delete(`shipping/${this.shipment.id}/purchase/details/${item.id}`)
          .then((response) => {
            if (response.data.code === 200) {
              this.$toast.info("Compra eliminada con exito!");
              this.getPrice();
            } else {
              this.$toast.error("No se pudo eliminar :(");
            }
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("No se pudo eliminar :(");
          })
          .finally(() => {
            this.dialogDelit = false;
            this.$store.dispatch("working", false);
          });
    },
    UploadFile: function (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    ConvertFile3: async function () {
      let documents = this.filesDrag3;

      for (const doc of documents) {
        const result = await this.UploadFile(doc);
        const data = result.split(",")[1];
        const dotPosition = doc.name.lastIndexOf(".");
        const fileExt = doc.name.substr(dotPosition + 1);
        const fileName =
            dotPosition === -1 ? doc.name : doc.name.substr(0, dotPosition);
        this.docConfirm.push({
          id: 0,
          documentTypeId: 103,
          file: data,
          fileName: fileName,
          fileExtension: fileExt,
          shippingPurchaseId: 0,
        });
        /*  this.edit.documents.documentTypeId = 103;
        (this.edit.documents.file = data),
          (this.edit.documents.fileName = fileName);
        this.edit.documents.fileExtension = fileExt; */
      }
      this.docConfirm.forEach((element) => {
        delete element.shippingPurchaseId;
      });
      this.docLleno = true;
      console.log(this.docConfirm);
    },
    ConvertFile2: async function () {
      let documents = this.filesDrag;

      for (const doc of documents) {
        const result = await this.UploadFile(doc);
        const data = result.split(",")[1];
        const dotPosition = doc.name.lastIndexOf(".");
        const fileExt = doc.name.substr(dotPosition + 1);
        const fileName =
            dotPosition === -1 ? doc.name : doc.name.substr(0, dotPosition);
        this.prices.documents.push({
          id: 0,
          documentTypeId: 103,
          file: data,
          fileName: fileName,
          fileExtension: fileExt,
          shippingPurchaseId: 0,
        });
        /*  this.edit.documents.documentTypeId = 103;
        (this.edit.documents.file = data),
          (this.edit.documents.fileName = fileName);
        this.edit.documents.fileExtension = fileExt; */
      }
      this.docLleno = true;
      console.log(this.edit);
    },
    ConvertFile: async function () {
      let documents = this.filesDrag2;

      for (const doc of documents) {
        const result = await this.UploadFile(doc);
        const data = result.split(",")[1];
        const dotPosition = doc.name.lastIndexOf(".");
        const fileExt = doc.name.substr(dotPosition + 1);
        const fileName =
            dotPosition === -1 ? doc.name : doc.name.substr(0, dotPosition);
        this.edit.documents.push({
          id: 0,
          documentTypeId: 103,
          file: data,
          fileName: fileName,
          fileExtension: fileExt,

        });
        /*  this.edit.documents.documentTypeId = 103;
        (this.edit.documents.file = data),
          (this.edit.documents.fileName = fileName);
        this.edit.documents.fileExtension = fileExt; */
      }
      this.docLleno = true;
      console.log(this.edit);
    },
    ConvertFile10: async function () {
      let documents = this.filesDrag2;

      for (const doc of documents) {
        const result = await this.UploadFile(doc);
        const data = result.split(",")[1];
        const dotPosition = doc.name.lastIndexOf(".");
        const fileExt = doc.name.substr(dotPosition + 1);
        const fileName =
            dotPosition === -1 ? doc.name : doc.name.substr(0, dotPosition);
        this.edit.documents.push({
          id: 0,
          documentTypeId: 103,
          file: data,
          fileName: fileName,
          fileExtension: fileExt,
          paymentRequestId: this.paymentId,
          invoiceNumber: this.summarie.invoiceNumber,
          invoiceIssuedAt: this.summarie.invoiceIssuedAt,
          totalAmount: parseFloat(this.summarie.totalAmount),
        });
        /*  this.edit.documents.documentTypeId = 103;
        (this.edit.documents.file = data),
          (this.edit.documents.fileName = fileName);
        this.edit.documents.fileExtension = fileExt; */
      }
      this.docLleno = true;
      console.log(this.edit);
    },
    openDialog1(provedor) {
      this.dialogEdit = true;
      console.log(provedor);
      this.aux = provedor;
    },
    editFee(provedor) {
      console.log(provedor);
      this.aux = [];
      let doc2 = {
        comments: this.saveComment,
        documents: this.edit.documents,
      };
      console.log(this.prices);
      this.$store.dispatch("working", true);
      this.$http
          .post(
              `Shipping/${this.shipment.id}/purchase/${this.prices.id}/detail/${provedor.id}/change`,
              doc2
          )
          .then((response) => {
            console.log(response);
            this.$toast.info("Ya puede editar la compra");
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("Ocurrio un error");
          })
          .finally(async () => {
            await this.getPrice();

            /* setTimeout(() => {
             }, 500)*/
            this.$store.dispatch("working", false);
          });
      // console.log(this.prices.details[0].shippingPurchaseId)
      this.dialogEdit = false;
      this.confirmPrices = false;
      this.edit.comments = "";
      this.edit.documents = [];
      this.docLleno = false;
      this.saveComment = "";
    },
    getExpnasionIndex(item) {
      console.log(item);
      //  indexExpansion
    },
    getCurrencyID(item) {
      console.log(item);
    },
    agregar(fee) {
      let precio = this.prices.purchase.details.find(
          (item) => item.id === fee.purchaseDetailId
      );
      console.log(precio);
      console.log(this.prices.purchase.details);
      precio.fees.push({
        container: {
          capacity: 0,
          description: null,
          height: 0,
          id: 1,
          large: 0,
          maximumWeight: 0,
          special: false,
          status: true,
          transportationId: 1,
          width: 0,
        },
        currency: {
          code: "USD",
          id: 1,
          name: "US dollar",
          status: true,
          symbol: "$",
        },
        containerId: 1,
        currencyId: 1,
        hasRet: false,
        isAdditionalCharge: false,
        hasVat: false,
        name: "cargo",
        price: 0,
        purchaseDetailId: precio.id,
        quantity: 0,
        ratableWeight: 0,
        status: false,
        total: 0,
      });
    },
    eliminar(fee, index) {
      console.log(fee);
      let precio = this.prices.purchase.details.find(
          (item) => item.id === fee.purchaseDetailId
      );
      precio.fees.splice(index, 1);
    },
    getPrice() {
      if (!this.shipment.id) {
        return
      }
      this.$store.dispatch("working", true);
      this.$http
          .get(`shipping/${this.shipment.id}/purchase`)
          .then((response) => {
            this.prices = [];
            this.prices = response.data.data;
            this.prices.details.forEach((element, index) => {
              this.prices.details[index].departureTime = this.$luxon(element.departureTime, "yyyy-MM-dd"
              )
              console.log(this.$luxon(element.departureTime, "yyyy-MM-dd"
              ))
              this.prices.details[index].fees = this.prices.details[index].fees.map((element) => {
                return {
                  ...element,
                  isSelected: false,
                  disable: false,
                  toKeep: true,
                  purchaseConcept: element.purchaseConcept ?? {
                    name: element.name,
                    id: 0
                  }
                }
              })
            })

            this.prices.details = this.prices.details.map((element) => {
              return {
                ...element,
                menu: false,
              }
            })
            /*this.prices.details((element, index) => {
              this.prices.details[index] =  this.prices.details[index].fees.map((fee) => {
                return {
                  ...fee,
                  test1: null,
                }
              })
            })*/
            if (this.shipment.transportationId === 1 || this.shipment.transportationId === 3) {
              if (this.shipment.transportationCategoryId === 9) {
                this.headers = [
                  {
                    text: "Cargo",
                    value: "name",
                  },
                  {
                    text: "Ratable weight",
                    value: "ratableWeight",
                  },

                  {
                    text: "Compra",
                    value: "price",
                  },
                  {
                    text: "Compra total",
                    value: "compraTotal"
                  },
                  {
                    text: "Moneda",
                    value: "currency.code",
                  },
                  {
                    text: "IVA",
                    value: "hasVat",
                  },
                  {
                    text: "IVA - Ret",
                    value: "hasRet",
                  },
                  {
                    text: "cargo adicional",
                    value: "isAdditionalCharge",
                  },

                  {
                    text: "",
                    value: "action",
                  },
                ];
              } else {
                this.headers = [
                  {
                    text: "Cargo",
                    value: "name",
                  },
                  {
                    text: "Cantidad",
                    value: "quantity",
                  },
                  {
                    text: "Compra",
                    value: "price",
                  },
                  {
                    text: "Compra total",
                    value: "compraTotal"
                  },
                  {
                    text: "Moneda",
                    value: "currency.code",
                  },
                  {
                    text: "IVA",
                    value: "hasVat",
                  },
                  {
                    text: "IVA - Ret",
                    value: "hasRet",
                  },
                  {
                    text: "cargo adicional",
                    value: "isAdditionalCharge",
                  },

                  {
                    text: "",
                    value: "action",
                  },
                ];
              }
            } else {
              this.headers = [
                {
                  text: "Cargo",
                  value: "name",
                },
                {
                  text: "Ratable weight",
                  value: "ratableWeight",
                },

                {
                  text: "Compra",
                  value: "price",
                },
                {
                  text: "Compra total",
                  value: "compraTotal"
                },
                {
                  text: "Moneda",
                  value: "currency.code",
                },
                {
                  text: "IVA",
                  value: "hasVat",
                },
                {
                  text: "IVA - Ret",
                  value: "hasRet",
                },
                {
                  text: "cargo adicional",
                  value: "isAdditionalCharge",
                },

                {
                  text: "",
                  value: "action",
                },
              ];
            }

            let discountFee = [];


            // aqui se sacan los fees que estan usados para las notas de credito y asi bloquearlos
            if (this.prices.discounts.length > 0) {
              this.prices.discounts.forEach((discount) => {
                discount.fees.forEach((fee) => {
                  discountFee.push(fee.purchaseFeeId)
                })
              })

              this.prices.details.forEach((detail, indexDetail) => {
                detail.fees.forEach((fee, indexFee) => {
                  discountFee.forEach((discount) => {
                    if (discount === fee.id) {
                      this.prices.details[indexDetail].fees[indexFee].disable = true;
                    }
                  })
                })
              })

              this.prices.details = this.prices.details.map((element, index) => {
                this.prices.details[index].fees = this.prices.details[index].fees.map((fee) => {
                  return {
                    ...fee,
                    NC: true,
                    toKeep: true,
                    purchaseConcept: fee.purchaseConcept ?? {
                      name: fee.name,
                      id: 0
                    }
                  }
                })
                return {
                  ...element
                }
              })
            }


            console.log('discountFee', discountFee)
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            let arr = [];
            this.prices.details.push(arr);
            this.prices.details.splice(-1);
            this.$store.dispatch("working", false);
            if (this.itemsSolicitudes) {
              this.prices.details.forEach((provedor, indexProv) => {
                provedor.fees.forEach((feeProvedor, indexfee) => {
                  this.itemsSolicitudes.forEach((solicitud) => {
                    solicitud.fees.forEach((feeSolicitud) => {
                      if (feeProvedor.id === feeSolicitud) {
                        this.prices.details[indexProv].fees[
                            indexfee
                            ].disable = true;
                      }
                    });
                  });
                });
              });
            }
          });
    },
    print() {
      // console.log(this.date)
      console.log(this.infoIndex);
      //  this.getCancelReason();
    },
    cancelConfirm() {
      console.log(this.infoIndex);
      this.prices.details[this.infoIndex].confirm = false;
      console.log(this.prices.details);
      this.dialogEdit2 = false;
      this.summarie.supplierId = 0;
    },
    confirmPrice(index, provedor) {
      this.infoIndex = index;
      this.dialogEdit2 = true;
      console.log(provedor);
      this.summarie.supplierId = provedor.supplierId;
    },
    setCurrency(index) {
      this.prices[index].priceFees.forEach((element) => {
        element.currencyId = this.currencyId;
      });
      this.mapAndUpdatePrice(index);
    },
    getCurrencies() {
      // this.$store.dispatch("working", true);
      this.$http.get("currencies").then((response) => {
        if (response.data.code == 200) {
          this.Currencies = response.data.data;
        }
      });
    },
    comment() {
      const time = new Date().toTimeString();
      this.events.push({
        id: this.nonce++,
        text: this.input,
        time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents) => {
          return ` ${contents
              .split(" ")
              .map((v) => v.charAt(0))
              .join("")}`;
        }),
      });

      this.input = null;
    },
    updateShipment(master) {
      console.log(master);
      console.log(this.shipment);
      let updateShiping = {
        IncotermId: null,
        ClientId: null,
        ShippingStatusId: null,
        ClientSupplierName: null,
        ReferenceNumber: null,
        ContactName: null,
        Mails: null,
        Comments: null,
        ChangeTypeId: null,
        QuotationId: null,
        MasterBl: {
          ShippingId: this.shipment.id,
          supplierId: master.shipping,
          ShipName: this.shipment.ShipName,
          TripNumber: master.BL,
          Shipper: master.shipper,
          CargoQuantity: null,
          CargoVolume: null,
          CargoWeigth: null,
          Consignee: master.consignee,
          DelaysInport: this.shipment.DelaysInport,
          StorageDays: this.shipment.StorageDays,
        },
        HouseBl: [
          {
            Shipper: null,
            Consignee: null,
            RefNumber: null,
            BlTypeId: null,
            CustomAgentId: null,
          },
        ],
      };
      console.log(updateShiping);
    },
    getCustomers: function () {
      this.$store.dispatch("working", true);
      this.$http
          .get("Companies")
          .then((response) => {
            console.log(response);
            this.companies = response.data.data;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$store.commit("reset");
              this.$toast.error("La sesion expiro");
              this.$router.replace("/login");
            }
          })
          .finally(() => this.$store.dispatch("working", false));
    },
    getShipping() {
      this.$http
          .get(`Shipping/${this.shipment.id}`)
          .then((response) => {
            this.prices = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    supplierChanged() {
      console.log("eNTRO");
      this.shipment.supplier = this.suppliers.find(
          (el) => el.id === this.shipment.supplierId
      );
      this.shipment.price.supplierId = this.shipment.supplierId;
      this.$http.put(`Orders/${this.shipment.orderId}/prices`, [
        this.shipment.price,
      ]);
    },
    confirmCharges() {
      console.log(this.prices.details[0].fees);
      this.selectedCargo = [];
      this.summaries[this.currentTab - 1].selectedCargo = [];
      this.summarySelected[this.currentTab - 1].selectedCargo = [];
      // var selected = [];
      this.chargesWorking = true;
      setTimeout(() => {
        this.chargesWorking = false;
        this.confimedCharges = true;
        this.$toast.info("Embarque actualizado");
        this.shipment.shippingStatusId = 2;
        this.confimedCharges = false;
        for (
            var counter = 0;
            counter < this.prices.details[this.expanded].fees.length;
            counter++
        ) {
          if (
              this.prices.details[this.expanded].fees[counter].selected === true
          ) {
            console.log("entre");
            console.log(this.prices.details[this.expanded].fees[counter]);
            // this.selectedCargo.push(this.demoCargos[counter]);
            this.summaries[this.currentTab - 1].selectedCargo.push(
                JSON.parse(
                    JSON.stringify(this.prices.details[this.expanded].fees[counter])
                )
            );
          }
        }
        // this.updateShippingStatus();
      }, 2000);
    },
    addCost(index, provedor) {
      let defaultFee = {
        id: 0,
        hasRet: false,
        isAdditionalCharge: false,
        hasVat: false,
        name: "default",
        price: 0,
        quantity: 1,
        ratableWeight: 1,
        status: false,
        total: 0,
        currencyId: 1,
        currency: {
          code: "USD",
          id: 1,
          name: "US dollar",
          status: true,
          symbol: "$",
        },
      };
      let indexProvedor = this.prices.details.findIndex(x => x.id === provedor.id)

      this.prices.details[indexProvedor].fees.push(
          JSON.parse(JSON.stringify(defaultFee))
      );
      console.log(this.prices);
    },
    removeCost(item, provedor, elIndex) {
      if (item.id === 0) {
        console.log(elIndex)
        let indexProvedor = this.prices.details.findIndex(x => x.id === provedor.id)
        console.log(indexProvedor)
        this.prices.details[indexProvedor].fees.splice(elIndex, 1)
      } else {
        this.$store.dispatch("working", true);
        console.log(item, provedor);
        this.$http
            .delete(
                `shipping/${this.shipment.id}/purchase/details/${provedor.id}/fee/${item.id}`
            )
            .then((response) => {
              if (response.data.code === 200) {
                this.$toast.info("Fee eliminado con exito!");
              } else {
                this.$toast.error("Algo salio mal");
              }
            })
            .catch((error) => {
              console.log(error);
              this.$toast.error("Algo salio mal");
            })
            .finally(() => {
              this.$store.dispatch("working", false);
              this.getPrice();
            });
      }

    },
    actualizarCompra(provedor) {

      this.$store.dispatch("working", true);
      this.prices.details.forEach((element) => {
        delete element.container;
        delete element.currency;
        delete element.supplier;
        element.fees.forEach((fee) => {
          fee.purchaseConceptId = fee.purchaseConcept.id
          element.purchaseConceptId = element.purchaseConceptId === 0 ? null : element.purchaseConceptId
          delete fee.container;
          delete fee.currency;
          delete fee.purchaseConcept;
        });
      });
      this.prices.details.forEach((detail) => {
        if (detail.freightForwarderId === 0) {
          detail.freightForwarderId = null;
        }
      });
      let obj = JSON.parse(JSON.stringify(provedor));
      let newFees = [];
      obj.fees.forEach((fee) => {
        if (!fee.disable) {
          console.log(fee)
          if (this.shipment.transportationId === 1 || this.shipment.transportationId === 3) {
            if (this.shipment.transportationCategoryId === 9) {
              console.log(fee)
              fee.total = fee.price * fee.ratableWeight;
              fee.quantity = 0

            } else {
              fee.total = fee.price * fee.quantity;
              fee.ratableWeight = 0
            }
          } else {
            fee.total = fee.price * fee.ratableWeight;
            fee.quantity = 0
          }
          newFees.push(fee);
          fee.quantity = Number(fee.quantity, 10);
          fee.purchaseConceptId = fee.purchaseConceptId === 0 ? null : fee.purchaseConceptId
        }
      });
      obj.fees = newFees;
      obj.additionalInfo = {
        purchaseDetailId: obj.id,
        numPlaca: 'string',
        numCaja: 'string',
        flightNumber: 'string',
        daysOfDelay: 0,
      }
      this.$http
          .put(
              `shipping/${this.prices.shippingId}/purchase/${this.prices.id}/detail/${provedor.id}`,
              obj
          )
          .then((response) => {
            if (response.data.code === 200) {
              this.$toast.info("Actualizado con exito!");
            } else {
              // EL PODEROSO EQUIPO DE BACK regresa errores asi, me encantan bola de idiotas
              console.log(response.data.message.errors[0][0].message.message, 'error')
              const jsonString = JSON.stringify(response);
              const targetMessage = 'Shipping route must be created first';
              if (jsonString.includes(targetMessage)) {
                this.$toast.error("Favor de llenar todos los eventos de la ruta (evento, origen y fecha)");
              } else {
                this.$toast.error("Algo salio mal");
              }

            }
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("Algo salio mal");
          })
          .finally(() => {
            /*setTimeout(() => {
              this.getPrice();
              this.getSolicitudes(this.provedorG, this.indexG);
            }, 500);*/
            let item = {
              id: this.shipment.id
            }
            this.$emit('updateCargo', item);
            setTimeout(() => {
              this.$store.dispatch("working", false);
            }, 1500)
          });
      //  this.$store.dispatch("working", false);
    },
    saveAndConfirm() {
      this.$store.dispatch("working", true);
      this.prices.isEditable = false;
      this.prices.details.forEach((element) => {
        delete element.container;
        delete element.currency;
        delete element.supplier;
        element.fees.forEach((element) => {
          delete element.container;
          delete element.currency;
        });
      });
      console.log(this.prices);
      this.$http
          .put(
              `shipping/${this.prices.shippingId}/purchase/${this.prices.id}`,
              this.prices
          )
          .then((response) => {
            if (response.data.code === 200) {
              console.log(response);
              this.$toast.info("Actualizado con exito!");
              this.getPrice();
            } else {
              this.$toast.error("Algo salio mal");
            }
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("Algo salio mal");
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });
    },
    changeConcept(item) {
      let index = this.itemsFiltered.findIndex(
          (x) => x.id === item.paymentConceptId
      );
      let indexCurrency = this.Currencies.findIndex(
          (x) => x.code === this.itemsFiltered[index].currency
      );
      item.paymentConcept = {
        id: this.itemsFiltered[index].id,
        status: true, // no se donde sale xd // en teoria debe venir un estado pero sera para el yo del futuro, vendria dentro de itemsConcept?
        name: this.itemsFiltered[index].name,
        iva: "string",
        account: "string",
        retIsr: this.itemsFiltered[index].retIsr,
        retIva: this.itemsFiltered[index].retIva,
        currencyId: this.Currencies[indexCurrency].id,
      };
    },
    send2NC(index) {
      let obj = this.summarie.Fees[index]
      obj.concept = obj.purchaseConcept
      obj.purchaseFeeId = obj.id
      obj.id = 0

      const ncFeeIndex = this.creditNotes[this.tabCreditNote]?.fees?.findIndex(x => x.total === obj.total) ?? -1
      if(ncFeeIndex !== -1) {
        this.summarie.Fees[index].NC = false
        this.creditNotes[this.tabCreditNote].fees.splice(ncFeeIndex, 1)
        return
      }

      this.summarie.Fees[index].NC = true;

      if(this.creditNotes[this.tabCreditNote] === undefined) {
        this.addCreditNote([obj])
        this.creditNoteTotals(this.creditNotes[this.tabCreditNote])
        return
      }

      this.creditNotes[this.tabCreditNote].fees.push(obj)
      this.creditNoteTotals(this.creditNotes[this.tabCreditNote])
    },
    send2Summary(item, provedor) {
      console.log(provedor);
      if (item.selected) {
        if (
            this.currencieSelected !== item.currency.code &&
            this.currencieSelected != ""
        ) {
          this.cleanSummarie2(item);
        }

        this.currencieSelected = item.currency.code;
        this.filterCurrency();
        // let purchId = JSON.parse(JSON.stringify(item.id));
        this.summarie.Fees.push(item);
        this.summarie.Fees = this.summarie.Fees.map((element) => {
          let purchId = 0
          if (element.id !== 0) {
            purchId = JSON.parse((JSON.stringify(element.id)))
          } else {
            purchId = JSON.parse((JSON.stringify(element.PurchaseFeeId)))
          }
          if (element.isSolicitud) {
            return {
              ...element,
            }
          } else {
            return {
              ...element,
              PurchaseFeeId: purchId,
              elTotal: element.ratableWeight * element.price + element.profit,
              conceptFirst: false,
              conceptId: element.conceptId ? element.conceptId : 0,
              searchConcetp: null,
              conceptItems: element.conceptItems ? element.conceptItems : [],
              id: element.isSolicitud ? element.id : 0,
              NC: false,
            }

          }
        })
        this.summarie.Fees.forEach((element) => {
          this.summarie.Fees.forEach((fee, index) => {
            // eslint-disable-next-line no-prototype-builtins
            if (this.summarie.Fees[index].hasOwnProperty('PurchaseFeeId')) {
              console.log('entre')
              console.log('purchaseGrande: ', element.purchaseFeeId, 'PurchasFeeId', fee.PurchaseFeeId)
              if (element.purchaseFeeId === fee.PurchaseFeeId) {
                fee.id = element.id
              }
            }

          })
        })
      } else {
        item.selected = false;
        this.currencieSelected = "";
        this.summarie.Fees.forEach((element, index) => {
          console.log(element.id, item.id)
          if (element.PurchaseFeeId === item.id) {
            this.summarie.Fees.splice(index, 1);
          }
        });
      }
    },
    format() {
      this.summarie.DueDate = this.summarie.DueDate + 'T00:00:0000'
    },
    deleteSupplierSummarie(index) {
      this.suppliersSummarie.splice(index, 1);
      this.setFirstLast(this.suppliersSummarie);
    },
    deleteFeeSupplierSummarie(indexS, index) {
      this.suppliersSummarie[indexS].fees.splice(index, 1)
    },
    addFeeSupplierSummarie(index) {
      console.log(index)
      let fee = {
        concept: null,
        searchConcept: null,
        searchConcetp: null,
        conceptId: null,
        name: '',
        quantity: 1,
        basePrice: 1,
        iva: 0,
        ivaAcreditable: "0",
        retIva: 0,
        retIsr: 0,
        ledgerAccount: 0
      }
      this.suppliersSummarie[index].fees.push(fee);
      let arr = [];
      this.suppliersSummarie[index].fees.push(arr);
      this.suppliersSummarie[index].fees.splice(-1);
    },
    addSupplierSummarie() {
      let supplierObj = {
        fees: [{
          concept: null,
          searchConcept: null,
          conceptId: null,
          name: '',
          quantity: 1,
          basePrice: 1,
          iva: 0,
          ivaAcreditable: "0",
          retIva: 0,
          retIsr: 0,
          ledgerAccount: 0
        }],
        rowSummarie: 1,
        id: 0,
        isLast: true,
        isFirst: false,
        supplierId: 0,
        forwarderId: 0,
        invoiceNumber: null,
        invoiceDocAux: [],
        invoiceDate: null,
        invoiceFileName: null,
        invoiceFileExtension: null,
        menu: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      }
      this.suppliersSummarie.push(supplierObj);
      this.setFirstLast(this.suppliersSummarie);
    },
    async checkInvoiceNumberStatus(summarie) {
      let somethingMissing = false;
      if (this.isFacturacion) {
        console.log(summarie)
        console.log(this.suppliersSummarie);
        summarie.suppliers = JSON.parse(JSON.stringify(this.suppliersSummarie));
        console.log(summarie)
        for (let index = 0; index < summarie.suppliers?.length; index++) {
          let element = summarie.suppliers[index];
          element.amount = 1;
          if (!element.invoiceDate) {
              somethingMissing = true;
          }
          if (!element.invoiceNumber) {
              somethingMissing = true;
          }

          let flag = await this.validateInvoiceNumber(element.supplierId, element.invoiceNumber);
          if (!flag) {
              this.$toast.error(`El numero de factura ${element.invoiceNumber} ya existe`);
              return false;
          }
          // quitar esta linea despues
          summarie.suppliers.invoiceDoc = null;
          delete summarie.suppliers[index].forwarderId;
          delete summarie.suppliers[index].menu;
          delete summarie.suppliers[index].isFirst;
          delete summarie.suppliers[index].isLast;
          delete summarie.suppliers[index].invoiceDocAux;
          console.log(element);
          element.fees.forEach((fee) => {
              fee.conceptId = fee.concept.id;
              fee.name = fee.concept.name;
          });
          // element.fees.map((element) => {
          //   return {
          //     ...element,
          //     iva: 0
          //   }
          // })
        }
        // summarie.suppliers.invoiceDoc = summarie.suppliers.invoiceDoc[0];
        // delete summarie.suppliers.menu;
        summarie.invoiceIssuedAt = null;
        summarie.invoiceNumber = null;

      } else {
        delete summarie.suppliers;
      }
      if (somethingMissing) {
        this.$toast.error('Porfavor llena la cantidad el provedor y la fecha de factura y el numero de factura')
        return false;
      }
      return summarie
    },
    async validateInvoiceNumber(supplierId, invoiceNumber) {
      try {
        const response = await this.$httpAdminApi({
          url: `operations/payment_request/validate/invoice?external_id=${supplierId}&folio=${invoiceNumber}`,
          method: "GET",
          responseType: "application/json"
      });
      return response.data.available;
      }catch(error){
        console.log("response api:", error);
      }
    },
    async sendSummary(isCreditNote = false) {
      if (!isCreditNote) {
        if(this.summarie.invoiceNumber === null || this.summarie.invoiceIssuedAt === null) return this.$toast.error("Favor de llenar los datos de factura");
        let flag = await this.validateInvoiceNumber(this.summarie.supplierId, this.summarie.invoiceNumber)
        if (!flag) return this.$toast.error(`El numero de factura ${this.summarie.invoiceNumber} ya existe`);
        if (this.summarie.documents.length < 0) {
          console.log('entre aqui')
          this.summarie.documents.totalAmount = this.summarie.totalAmount,
              this.prices.details.forEach((element) => {
                delete element.currency;
                delete element.supplier;
                element.fees.forEach((element) => {
                  delete element.container;
                  delete element.currency;
                  delete element.purchaseConcept;
                });
              });
        }
          console.log(this.suppliersSummarie)
          this.summarie.dueDate = this.summarie.DueDate;

          this.summarie.purchaseId = 0;
          console.log(this.summarie.purchaseId);
          console.log(this.summarie);
          let fees = this.summarie.Fees.map((element) => {
            return {
              id: 0,
              paymentRequestId: element.paymentRequestId,
              purchaseFeeId: element.PurchaseFeeId,
              currencyId: element.currencyId,
              price: element.price,
              quantity: element.quantity,
              ratableWeight: element.ratableWeight,
              hasVat: element.hasVat,
              hasRet: element.hasRet,
              name: element.purchaseConcept.name,
              feeStatusPurchaseId: 1,
              conceptId: element.purchaseConceptId,
              total: element.total,
              containerId: 1,
              paymentConcept: {
                ...element.purchaseConcept,
                currency: null
              },
              purchaseConcept: {
                ...element.purchaseConcept,
                currency: null
              },
              purchaseDetailId: element.purchaseDetailId
            }
          });
          this.summarie.Fees = fees
          this.summarie.Description
          this.summarie.purchaseDetailId = this.summarie.Fees[0].purchaseDetailId;
          delete this.summarie.purchaseId;
          this.summarie.Id = 0;
          this.summarie.id = 0;
          this.summarie.total = this.totals.subTotal
          this.summarie.TotalAdvance = 0;
          this.summarie.notification = this.notification;
          let toSend = JSON.parse(JSON.stringify(this.summarie))
          console.log(toSend)
          toSend = await this.checkInvoiceNumberStatus(toSend)

          if (!toSend) {
            this.$store.dispatch("working", false);
            return
          }
          if (toSend.suppliers) {
            toSend?.suppliers.forEach((supplier) => {
              supplier.fees = supplier.fees.map((fee) => {
                return {
                  ...fee,
                  ivaAcreditable: 0,
                  ledgerAccount: 0,
                  retIva: 0,
                  retIsr: 0
                };
              });
            });
          }
          console.log('toSendFinal', toSend);
          let tosend2 = JSON.parse(JSON.stringify(toSend));
          this.$store.dispatch('working', true);
          console.log('tosend2', tosend2)
          this.$http
              .post(
                  "/Shipping/" + this.shipment.id + "/payment/request",
                  tosend2
              )
              .then((response) => {
                console.log(response.data)
                if (response.data.code === 400) {
                  this.$toast.error(response.data);
                } else {
                  this.$toast.info("Solicitud creada con exito");
                  this.getS3(response.data.data.suppliers)
                }
              })
              .catch((error) => {
                console.log(error);
                this.$toast.error(error);
              })
              .finally(() => {
                this.$store.dispatch("working", false);
                this.paymentRequest = false;

                this.getPrice();
                this.cleanSummarie();
                console.log(this.provedorG, this.indexG);
                setTimeout(() => {
                  console.log("work");
                  this.getSolicitudes(this.provedorG, this.indexG);
                }, 1000);
              });
      } else {
        let totalPrice = JSON.parse(JSON.stringify(this.totals))
        console.log(totalPrice.price)
        this.summarie.total = totalPrice.price
        console.log(this.summarie.total = totalPrice.price)
        this.summarie.itemsNC.forEach((element) => {
          /*          element.PurchaseFeeId = element.id;*/
          element.FeeStatusPurchaseId = 1;
          delete element.container;
          delete element.currency;
          delete element.purchaseConcept;
          delete element.paymentConcept;
          element.containerId = 1;
          element.paymentConceptId;
          element.id = 0;
        });
        this.summarie.itemsNC.forEach((element, index) => {
          if ((this.shipment.transportationId === 1 || this.shipment.transportationId === 3) && this.shipment.transportationCategoryId !== 9) {
            console.log("")
          } else {
            this.summarie.itemsNC[index].ratableWeight = element.ratableWeight
            this.summarie.itemsNC[index].quantity = 0
          }
        })


        const ncFees = this.summarie.itemsNC.map(item => {
          return {
            ...item,
            conceptId: item.conceptId.id
          }
        })

        let notaCredito = {
          Id: 0,
          Total: this.summarie.itemsNC.reduce((acc, item) => acc + item.total, 0),
          ShippingPurchaseId: this.prices.id,
          SupplierId: this.summarie.supplierId,
          InvoiceNumber: this.summarie.invoiceNumber,
          Fees: ncFees,
          paymentRequestId: this.summarie.id,
          issuedAt: this.summarie.issuedAt
        }
        let hasConcept = true
        if (hasConcept) {
          this.$store.dispatch("working", true);
          this.$http.post("/Shipping/" + this.shipment.id + "/purchase/" + this.prices.id + '/discounts', notaCredito).then((response) => {
            console.log(response)
            if (response.data.code === 200) {
              this.$toast.info("Se ha generado una nota de credito!");
            } else {
              this.$toast.error("Ocurrio un error");
            }
          }).catch((error) => {
            console.log(error)
          }).finally(() => {
            this.$store.dispatch("working", false);
            this.creditNoteCreat = false;
            this.getPrice();
            this.cleanSummarie();
            console.log(this.provedorG, this.indexG);
            setTimeout(() => {
              console.log("work");
              this.getSolicitudes(this.provedorG, this.indexG);
            }, 1000);
          })
        }

      }
    },

    PaymentAdvanced(response) {

      let doc = {
        payLeftAmount: Number(this.summarie.TotalAdvance),
      }
      console.log(`/Shipping/${this.shipment.id}/payment/${response.data.data.id}/payLeftAmount`),
          this.$http.put(`/Shipping/${this.shipment.id}/payment/${response.data.data.id}/payLeftAmount`, doc).then((response) => {
            if (response.data.code === 200) {
              this.$toast.info("Confirmado con exito!");
            } else {
              this.$toast.error("Ocurrio un error");
            }
            console.log("No paso")
          }).catch((error) => {
            console.log(error)
          }).finally(() => {
          })

    },

    PaymentAdvancedSolo() {
      this.$store.dispatch("working", true);

      let doc = {
        payLeftAmount: Number(this.summarie.aPagar),
      }
      this.$http.put(`/Shipping/${this.shipment.id}/payment/${this.summarie.id}/payLeftAmount`, doc).then((response) => {
        if (response.data.code === 200) {
          this.$toast.info("Confirmado con exito!");
        } else {
          this.$toast.error("Ocurrio un error");
        }
        console.log("No paso")
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.cleanSummarie();
        this.$store.dispatch("working", false);
        this.restPayment = false;
        setTimeout(() => {
          console.log("work");
          this.getSolicitudes(this.provedorG, this.indexG);
        }, 1000);
      })

    },
    selectedRow(item) {
      console.log(item);
      // this.editedIndex = indexOf[item];
      if (!("selected" in item)) {
        console.log("work");
        item.selected = true;
      } else {
        if (item.selected === false) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      }

      console.log(item);
      this.send2Summary();
    },
    setFirstLast: function (currItems) {
      // first check that actually exists values
      if (currItems.length) {
        // toggle all to false
        currItems.forEach((x) => {
          x.isLast = false;
          x.isFirst = false;
        });
        // just set first to true
        currItems[currItems.length - 1].isLast = true;
        currItems[0].isFirst = true;
      }
    },
    newContainer: debouncePromise(function (event) {
      console.log(event);
      const container = event;
      let dataServer = JSON.parse(JSON.stringify(this.shipmentToServer));
      dataServer.cargo = container;
    }, 1000),
    updateShippingStatus() {
      this.shipmentWorking = true;
      this.$http
          .put(
              `Shipping/${this.shipment.id}/status/${this.shipment.shippingStatusId}`
          )
          .then((response) => {
            this.$refs.statusBar.getCount();
            this.$toast.info("Embarque actualizado.");

            console.log(response);
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error(error);
          })
          .finally(() => {
            this.shipmentWorking = false;
          });
    },
    getIncoterms() {
      this.$http
          .get("Incoterms")
          .then((response) => {
            this.incoterms = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    getOperators() {
      this.$http
          .get("Employees/operators")
          .then((response) => {
            this.operators = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    findForwarder: debounce(function(name){
      this.getForwarders(name)
    }, 300),
    getForwarders: function (name) {
      this.loadingForwarders = true
      this.$http.get(`Suppliers/forwarders?name=${name ?? ""}`)
          .then((response) => {
            if (response.data.code == 200) {
              this.forwarders = response.data.data;
            }

            if (response.data.code == 400 || response.data.code == 500)
              this.$toast.error(response.data);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$store.dispatch("working", false);
            this.loadingForwarders = false
          });
    },

    autosaveShipping: debouncePromise(function (master) {
      this.updateShipment(master);
    }, 5000),
    setPaymentDate() {

      if (this.credit) {
        this.summarie.DueDate = null;
        this.paymentForm = true;
      }


    }
  },
};
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.timeline {
  display: block;
  position: relative;
}

>>> .timeline .v-input__slot {
  padding: 0 6px !important;
}

>>> .timeline .v-input__slot .v-label {
  margin-left: 6px;
}

.wrap {
  margin-left: 21rem;
  margin-right: 3.5rem;
}

.wrap_xl {
  margin-left: 21rem;
  margin-right: 27rem;
}

>>> .v-expansion-panel-content__wrap {
  padding-left: 0px;
}

>>> .v-alert__icon.v-icon {
  margin-top: 6px;
}

>>> .v-expansion-panel-content__wrap {
  padding: 0;
}

>>> .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 0px;
}

>>> .mdi-chevron-down::before {
  content: none;
}
</style>
